
import React from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import Button from '../../Components/Button';
import Card from "../../Components/Card";
import EvalInput from "../../Components/Eval/EvalInput";
import Steps from '../../Components/Steps';
import useBEM from "../../useBEM";
import { GetVisionContent } from '../../Utils/EvalContent';

function EvalVision() {
    const [evaluation, persistEvaluation] = useOutletContext();
    const [block, element] = useBEM('eval-content');

    const persist = (partial) => {
        persistEvaluation({
            Vision: {
                ...evaluation.Vision,
                ...partial
            }
        });
    };

    const evalInputProps = GetVisionContent(evaluation, persist);

    const evalInputsMap = evalInputProps.map((props, i) => (
        <EvalInput {...props} key={i} />
    ));

    const disabled = !evaluation?.Vision?.LookingAhead || evaluation?.Vision?.LookingAhead === 0 ||
                     !evaluation?.Vision?.SituationalAwareness || evaluation?.Vision?.SituationalAwareness === 0 ||
                     !evaluation?.Vision?.FlagStations || evaluation?.Vision?.FlagStations === 0 ||
                     !evaluation?.Vision?.WaypointUsage || evaluation?.Vision?.WaypointUsage === 0;
                     
    const navigate = useNavigate();
    return (
        <div className={block()}>
            <Card>
                <h1 className={element('title')}>Vision</h1>
                <p className={element('description')}>
                    Rate your student using the scale below. 
                    1 is novice level and 4 is advanced. 
                    After selection a brief description will be shown with what it entails.
                </p>
                <div className={element('inputs')}>
                    {evalInputsMap}
                </div>
                <div className={element('continue')}>
                    <Button 
                        label="Continue" 
                        disabled={disabled} 
                        onClick={() => navigate("../track")} />
                </div>
                <Steps total={5} step={0} />
            </Card>
        </div>
    );
}

export default EvalVision;