import useBEM from '../useBEM';
import './Button.scss';

const Button = (props) => {
    const { disabled, label, onClick, kind } = props;
    const [block] = useBEM('button');
    return (
        <button 
            onClick={() => disabled ? null : onClick() }
            className={block([disabled ? 'disabled' : 'active', kind])}>{label}</button>
    );
}

export default Button;