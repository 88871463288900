import React, { useState } from 'react';
import useBEM from '../../useBEM';

import EditIcon from "../../Media/edit.svg";
import Card from '../../Components/Card';

import Button from '../../Components/Button';
import ImageUploader from '../../Components/ImageUploader';


function ProfileGarage(props) {
    const {garage, setGarage, makeDirty} = props;
    // eslint-disable-next-line no-unused-vars
    const [block, element] = useBEM('profile');
    const [section, setSection] = useState('list');
    const [editIndex, setEditIndex] = useState(-1);

    const [car, setCar] = useState(null);

    const backupUrl = process.env.REACT_APP_CDN + 'default.png'

    const updateCar = (update) => {
        setCar({...car, ...update});
        makeDirty();
    };

    const setDefaultCar = (car) => {
        for (const oldCar of garage) {
            oldCar.IsDefault = false;
        }
        car.IsDefault = true;
        setGarage(garage);
        makeDirty();
    }

    const addCar = () => {
        setCar({Name: 'New Car'});
        setEditIndex(-1);
        makeDirty();
    }

    const editCar = (e, car, index) => {
        e.stopPropagation();
        setCar({...car});
        setEditIndex(index);
    }

    const saveCar = async () => {
        if (car.IsDefault) {
            for (const oldCar of garage) {
                oldCar.IsDefault = false;
            }
        }

        if (editIndex >= 0) {
            garage[editIndex] = car;
        } else {
            garage.push(car);
        }

        setGarage(garage);
        setCar(null);
        setEditIndex(-1);
        setSection('list');
    };

    const deleteCar = (index) => {
        if (window.confirm('Are you sure you want to delete this car?')) {
            garage.splice(index, 1);
            setGarage(garage);
            setCar(null);
            setEditIndex(-1);
            setSection('list');
        }
    }

    const backClick = () => {
        setCar(null);
        setSection('list');
    }


    const showNewForm = (garage && garage.length === 0) || section === 'new';
    const showEditForm = (garage && car != null);

    
    const carCards = garage?.map((car, index) => {
        const carDisplay = car?.Name ? car?.Name : `${car?.Year} ${car?.Make} ${car?.Model}`;
        return <div className={element('profile-garage-car', car?.IsDefault === true ? 'default' : '')} key={index} onClick={(e) => setDefaultCar(car)}>
            <Card>
                <div className={element('profile-garage-picture')}>
                    <div className={element('profile-garage-dummy')} ></div>
                    <img className={element('profile-garage-image')} src={car?.PictureUrl ?? backupUrl} alt="Car" />
                    <img className={element('profile-garage-icon')} src={EditIcon} onClick={(e) => editCar(e, car, index)} alt="Car Edit Icon" />
                </div>
                <div className={element('car-card-name')}>{carDisplay}</div>
            </Card>
        </div> 
    });

    return (
        <div className={element('profile-garage')}>
            
            
            {(!showNewForm && !showEditForm) && (
                <>
                    <div className={element('profile-garage-list')}>{carCards}</div>
                    <Button 
                        className={element('addcar')}
                        onClick={() => addCar()}
                        label="Add" />
                </>
                
                
            )}

            {(showNewForm || showEditForm) && (
            <div className={element('car-form')}>
                { garage && garage.length > 0 && (
                    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                    <a className={element('back')} href="#" onClick={() => backClick()}>Back</a>
                )} 

                <div className={element('field', 'car-picture')}>
                    <div className={element('profile-garage-car', car?.IsDefault === true ? 'default' : '')} onClick={(e) => updateCar({ IsDefault: !car?.IsDefault })}>
                        <Card>
                            <div className={element('profile-garage-picture')}>
                                <div className={element('profile-garage-dummy')}></div>
                                <img className={element('profile-garage-image')} src={car?.PictureUrl ?? backupUrl} alt='Car' />
                            </div>
                        </Card>
                    </div> 

                    <ImageUploader link={car?.PictureUrl} setLink={(link) => updateCar({ PictureUrl: link })} />
                </div>
                
                <div className={element('field', 'car-name')}>
                    <label className='text' htmlFor='name'>
                        Name: 
                    </label>
                    <input className='text' type="text" id='car-name' placeholder='Name' value={car?.Name ?? ''} onChange={(e) => updateCar({ Name: e.target.value })}></input>
                    <img className={element('field-icon')} src={EditIcon} alt={`Edit name Icon`} />
                </div>
                <div className={element('field', 'car-make')}>
                    <label className='text' htmlFor='car-make'>
                        Make: 
                    </label>
                    <input className='text'  type="text" id='car-make' placeholder='Make' value={car?.Make ?? ''} onChange={(e) => updateCar({ Make: e.target.value })}></input>
                    <img className={element('field-icon')} src={EditIcon} alt={`Edit make Icon`} />
                </div>

                <div className={element('field', 'car-model')}>
                    <label className='text' htmlFor='car-model'>
                        Model: 
                    </label>
                    <input className='text' id='car-model' type="text" placeholder='Model' value={car?.Model ?? ''} onChange={(e) => updateCar({ Model: e.target.value })}></input>
                    <img className={element('field-icon')} src={EditIcon} alt={`Edit car-model Icon`} />
                </div>

                <div className={element('field', 'car-year')}>
                    <label className='text' htmlFor='car-year'>
                        Year: 
                    </label>
                    <input className='text' id='car-year' type="text" placeholder='Year' value={car?.Year ?? ''} onChange={(e) => updateCar({ Year: e.target.value })}></input>
                    <img className={element('field-icon')} src={EditIcon} alt={`Edit year Icon`} />
                </div>

                <div className={element('field', 'car-modifications')}>
                    <label className='text' htmlFor='car-modifications'>
                        Modifications: 
                    </label>
                    <input className='text' id='car-modifications' type="text" placeholder='Modifications' value={car?.Modifications ?? ''} onChange={(e) => updateCar({ Modifications: e.target.value })}></input>
                    <img className={element('field-icon')} src={EditIcon} alt={`Edit modifications Icon`} />
                </div>
                
                <div className={element('savecar')}>
                    <Button 
                        onClick={() => saveCar()}
                        label="Save" />
                </div>
                
                {!showNewForm && (
                <div className={element('deletecar')}>
                    <Button 
                        onClick={() => deleteCar(editIndex)}
                        label="Delete" />
                </div>
                )}


            </div>
            )}

        </div>
    );
}

export default ProfileGarage;