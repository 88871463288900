
import React, { useState } from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import Button from '../../Components/Button';
import Card from "../../Components/Card";
import useBEM from "../../useBEM";
import BackIcon from "../../Media/arrow-left.svg";
import EvalSummary from '../../Components/Eval/EvalSummary';
import Loader from '../../Components/Loader';
import { getConsistencyContent, getInputsContent, getTrackUseContent, GetVisionContent } from '../../Utils/EvalContent';
import EvalSummaryNotes from '../../Components/Eval/EvalSummaryNotes';
import api from '../../api';


function EvalSubmit() {
    const [evaluation] = useOutletContext();
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [block, element] = useBEM('eval-content');

    const disabled = evaluation?.Vision?.LookingAhead === 0 ||
                     evaluation?.Vision?.SituationalAwareness === 0 ||
                     evaluation?.Vision?.FlagStations === 0 ||
                     evaluation?.Vision?.WaypointUsage === 0;
    
    var visionContent = GetVisionContent(evaluation, () => {});
    var trackUseContent = getTrackUseContent(evaluation, () => {});
    var consistencyContent = getConsistencyContent(evaluation, () => {});
    var inputsContent = getInputsContent(evaluation, () => {});

    const navigate = useNavigate();

    const submit = async () => {
        setIsLoading(true);
        const success = await api.PostEval(evaluation);
        setIsLoading(false);
        if (success) {
            setMessage('Success!');
        } else {
            setMessage('Failed.');
        }
    }

    if (message !== '') {
        return (
            <div className={block()}>
                <Card>
                    <h1 className={element('message')}>{message}</h1>
                </Card>
            </div>
        )
    }

    return (
        <div className={block()}>
            <Loader isLoading={isLoading}>
                <Card>
                    <button className={element('back')} onClick={() => navigate('../notes')}>
                        <img src={BackIcon} alt="Back Icon" />
                    </button>
                    <h1 className={element('title')}>Summary</h1>

                    <div className={element('summaries')}>
                        <EvalSummary properties={visionContent} label='Vision' labelLink='../vision'></EvalSummary>
                        <EvalSummary properties={trackUseContent} label='Track Use' labelLink='../track'></EvalSummary>
                        <EvalSummary properties={inputsContent} label='Inputs' labelLink='../inputs'></EvalSummary>
                        <EvalSummary properties={consistencyContent} label='Consistency' labelLink='../consistency'></EvalSummary>
                        <EvalSummaryNotes notes={evaluation?.PrivateNotes} label='Internal Notes' labelLink='../notes'></EvalSummaryNotes>
                        <EvalSummaryNotes notes={evaluation?.PublicNotes} label='External Notes for Student' labelLink='../notes'></EvalSummaryNotes>
                    </div>

                    <div className={element('continue')}>
                        <Button 
                            label="Submit" 
                            disabled={disabled} 
                            onClick={() => submit()} />
                    </div>
                </Card>
            </Loader>

        </div>
    );
}

export default EvalSubmit;