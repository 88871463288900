
import React from 'react';
import { useOutletContext } from "react-router-dom";
import Card from "../../Components/Card";
import useBEM from "../../useBEM";

import { getConsistencyContent, getInputsContent, getTrackUseContent, GetVisionContent } from '../../Utils/EvalContent';
import EvalSummary from '../../Components/Eval/EvalSummary';
import EvalSummaryNotes from '../../Components/Eval/EvalSummaryNotes';

function EvalView() {
    const [evaluation] = useOutletContext();
    const [block, element] = useBEM('eval-content');

    var visionContent = GetVisionContent(evaluation, () => {});
    var trackUseContent = getTrackUseContent(evaluation, () => {});
    var consistencyContent = getConsistencyContent(evaluation, () => {});
    var inputsContent = getInputsContent(evaluation, () => {});

    return (
        <div className={block()}>
            <Card>
                <h1 className={element('title')}>Summary</h1>

                <div className={element('summaries')}>
                    <EvalSummary properties={visionContent} label='Vision'></EvalSummary>
                    <EvalSummary properties={trackUseContent} label='Track Use'></EvalSummary>
                    <EvalSummary properties={inputsContent} label='Inputs'></EvalSummary>
                    <EvalSummary properties={consistencyContent} label='Consistency'></EvalSummary>
                    <EvalSummaryNotes notes={evaluation?.PrivateNotes} label='Internal Notes'></EvalSummaryNotes>
                    <EvalSummaryNotes notes={evaluation?.PublicNotes} label='External Notes for Student'></EvalSummaryNotes>
                </div>
            </Card>
        </div>
    );
}

export default EvalView;