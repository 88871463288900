import useBEM from '../useBEM';
import './Loader.scss';

const Background = (props) => {
    const { children, isLoading } = props;
    const [block] = useBEM('loader')
    if (isLoading) {
        return <div className={block()}></div>
    }
    else {
        return <>{children}</>
    }
}

export default Background;