import useBEM from '../useBEM';

const ProfilePicture = (props) => {
    const { picture } = props;
    const [block] = useBEM('profile-picture')
    const backupUrl = process.env.REACT_APP_CDN + 'profile/default.png'

    return <img src={picture ? picture : backupUrl} className={block()} alt="profile" />
}

export default ProfilePicture;