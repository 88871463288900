

function NotFoundPage() {
    return (
        <div>
            Not Found
        </div>
    );
}

export default NotFoundPage;