import { Outlet } from "react-router-dom";
import Background from "../Components/Background";
import Navigation from "../Components/Navigation";
import "./Layout.scss";

const Layout = () => {
  return (
    <>
      <Background>
        <Navigation />
        <Outlet />
      </Background>
    </>
  )
};

export default Layout;