
import React from 'react';
import "./EvalField.scss";
import {fieldTypes} from "../../Utils/EvalContent";
import EvalFieldSlider from './EvalFieldSlider';
import EvalFieldTextArea from './EvalFieldTextArea';

function EvalField(props) {
    const {field, answer, onValueChanged} = props;
    const {type} = field;

    switch (type) {
        case fieldTypes.SLIDER:
            return <EvalFieldSlider field={field} answer={answer} onValueChanged={onValueChanged} />;
        case fieldTypes.TEXTAREA:
            return <EvalFieldTextArea field={field} answer={answer} onValueChanged={onValueChanged} />;
        default:
            return <></>;
    }
}

export default EvalField;