import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Card from "../../Components/Card";
import Loader from "../../Components/Loader";
import useBEM from "../../useBEM";
import api from '../../api';
import { StateContext } from '../../App';

function EvalClaim() {
    const [state] = useContext(StateContext);
    const { evalId } = useParams();
    const [isReady, setIsReady] = useState(false);
    const [block] = useBEM('eval-content');

    useEffect(() => {
        async function claimEval() {
            var claim = await api.ClaimEval(evalId);
            if (claim) {
                window.location = `/eval/${evalId}/vision`;
            } else {
                setIsReady(true);
            }
        };
        setIsReady(false);
        if (evalId && state.user) {
            claimEval();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user, evalId]);

    return (
        <div className={block()}>
            <Card>
                <Loader isLoading={!isReady}>
                    <p>Unable to claim {evalId}.</p>
                </Loader>

            </Card>
        </div>
    );
}

export default EvalClaim;