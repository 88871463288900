
import React from 'react';
import Card from "../Card";
import useBEM from '../../useBEM';
import "./EvalSummary.scss";
import EditIcon from "../../Media/edit.svg";
import { Link } from 'react-router-dom';

function EvalSummaryNotes(props) {
    const [block, element] = useBEM('eval-summary');
    const { label, labelLink, notes } = props;

    return (
        <div className={block()}>
            <Card>
                <div className={element('label')}>
                    {!!labelLink && (
                        <Link to={labelLink}>
                            {label}
                            <img src={EditIcon} alt={`Edit ${label} Icon`} />
                        </Link>
                    )}
                    {!labelLink && (
                        <>{label}</>
                    )}
                </div>
                <div className={element('properties')}>
                    {notes}
                </div>
            </Card>
        </div>
    );
}

export default EvalSummaryNotes;