
import React, { useEffect, useState } from 'react';
import Card from "../Card";
import useBEM from '../../useBEM';
import "./EvalSummary.scss";
import EditIcon from "../../Media/edit.svg";
import { Link } from 'react-router-dom';

function EvalSummary(props) {
    const [block, element] = useBEM('eval-summary');
    const [ average, setAverage ] = useState(0);
    const { properties, label, labelLink } = props;
    
    const mappedProperties = properties.map((prop, i) =>
        <div key={i} className={element('property')}>
            <span className={element('property-label')}>{prop.label}</span>
            <span className={element('value')}>{prop.value === -1 ? 'N/A' : prop.value}</span>
        </div>
    );

    useEffect(() => {
        const values = properties.map(property => property.value);
        let total = 0;
        for (var value of values) {
            if (value > 0) {
                total += value;
            }
        }
        var average = (total / values.length);
        setAverage(average);
    }, [properties]);

    

    return (
        <div className={block()}>
            <Card>
                <div className={element('label')}>
                    {!!labelLink && (
                        <Link to={labelLink}>
                            {label}
                            <img src={EditIcon} alt={`Edit ${label} Icon`} />
                        </Link>
                    )}
                    {!labelLink && (
                        <>{label}</>
                    )}
                </div>
                <div className={element('properties')}>
                    {mappedProperties}
                </div>
                <div className={element('graph')}>
                    <div className={element('graph-labels')}>
                        <div className={element('graph-label')}>{label}</div>
                        <div className={element('graph-average')}>{average.toFixed(1)} out of 4</div>
                    </div>
                    <div className={element('graph-outer')}>
                        <div className={element('graph-inner')} style={{width: `${average * (100 / 4)}%` }}>
                            
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default EvalSummary;