
import React from 'react';
import useBEM from '../../useBEM';
import { Link } from "react-router-dom";
import "./InstructorEvalCard.scss";

function InstructorEvalCard(props) {
    const {evaluation} = props;
    const [block, element] = useBEM('instructor-eval-card');

    const date = new Date(evaluation?.EvalModel?.EventDate);
    
    return (
        <Link to={props?.link} className={block()}>
            <div>{date?.toLocaleDateString() ?? '?'}</div>
            <div>{evaluation.EvalModel?.Track}</div>
            <div>Apex Score:</div>
            <div className={element('score')}>{evaluation?.EvalModel?.ApexScore?.toFixed(1) ?? '?'}</div>
        </Link>
    );
}

export default InstructorEvalCard;