import React from 'react';
import Button from './Button';

const FileUploader = props => {
  const { disabled, label, onFileSelect, kind } = props;
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  
  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      onFileSelect(fileUploaded);
    }
  };

  return (
    <>
      <Button onClick={handleClick} label={label} disabled={disabled} kind={kind}  />
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      />
    </>
  );
}

export default FileUploader;