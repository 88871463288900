import React, { useContext, useEffect } from 'react';
import useBEM from "../useBEM";
import { StateContext } from '../App';

function HomePage() {

    const [state] = useContext(StateContext);
    const [block] = useBEM('homepage');

    useEffect(() => {
        async function redirect() {
            if (state?.user?.userRoles?.find) {
                if (state.user.userRoles.find(x=>x === 'admin')) {
                    window.location = `/admin`;
                }
                else if (state.user.userRoles.find(x=>x === 'instructor')) {
                    window.location = `/instructor`;
                }
                else if (state.user.userRoles.find(x=>x === 'student')) {
                    window.location = `/student`;
                }
                else {
                    window.location = `/profile`;
                }
            }
        };
        if (state.user) {
            redirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user]);

    return (
        <div className={block()}>
        </div>
    );
}

export default HomePage;