import useBEM from '../useBEM';
import './Background.scss';

const Background = (props) => {
    const { children } = props;
    const [block, element] = useBEM('background')
    return (
        <div className={block()}>
            <div className={element('image-container')}>
                <div className={element('overlay')}></div>
                <img className={element('image')} src="https://apexevalsstorage.blob.core.windows.net/public/backgrounds/_A097988_scaled.jpg" alt="background" />
            </div>
            <div className={element('content-container')}>
                {children}
            </div>
        </div>
    );
}

export default Background;