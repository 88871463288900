import { Link } from 'react-router-dom';
import useBEM from '../useBEM';
import ProfilePicture from './ProfilePicture';
import './UserCard.scss';

const UserCard = (props) => {
    const { profile, link } = props;
    const [block,element] = useBEM('user-card');

    const cardContent = (
        <>
            <div className={element('picture')}>
                <ProfilePicture picture={profile?.Picture} />
            </div>
            <div className={element('name')}>{profile?.DisplayName}</div>
            { (profile.identity || profile.provider) && 
                <div className={element('identity')}>{profile.provider} {profile.identity}</div>
            }
        </>
    );

    if (link) {
        return (
            <div className={block()}>
                <Link to={link} className={element('link')}>
                    {cardContent}
                </Link>
            </div>
        );
    } else {
        return (
            <div className={block()}>
                {cardContent}
            </div>
        );
    }
}

export default UserCard;