import React from 'react';
import useBEM from "../../useBEM";

import "../Student/Student.scss";
import InstructorEvalCard from './InstructorEvalCard';
import InstructorProgressChart from '../../Components/InstructorProgressChart';
import Card from '../../Components/Card';

function InstructorProgress(props) {
    const {evals, historyLinkPrefix} = props;
    const [block,element] = useBEM('student-dashboard');

    const historyRender = evals.length === 0 ? 
        <></>
        : evals.map((evalWithProfile, i) =>
            <InstructorEvalCard key={i} evaluation={evalWithProfile} link={ (historyLinkPrefix ?? '/instructor/eval/') + `${evalWithProfile?.EvalModel?.EvalId}`} />
    );

    console.log(historyRender);

    return (
        <div className={block()}>

            <div className={element('charts-instructor')}>
                <Card>
                    <div className={element('apex')}>
                        <h2 className={element('subtitle')}>Overall Apex Score</h2>
                        <InstructorProgressChart evals={evals} />
                    </div>
                </Card>
            </div>

            <div className={element('submissions')}>

                <h2 className={element('title')}>Driving History</h2>
                <div className={element('slide')}>
                    {historyRender}
                </div>
            </div>
        </div>
    );
}

export default InstructorProgress;