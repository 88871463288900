import React from 'react';
import useBEM from '../../useBEM';

import EditIcon from "../../Media/edit.svg";

import ImageUploader from '../../Components/ImageUploader';

function ProfileInfo(props) {
    const {profile, updateProfileState} = props;
    // eslint-disable-next-line no-unused-vars
    const [block, element] = useBEM('profile');

    return (
        <div className={element('profile-info')}>
            <ImageUploader link={profile?.Picture} setLink={(link) => updateProfileState({ Picture: link })} />
            <div className={element('field', 'name')}>
                <label className='text' htmlFor='displayName'>
                    Display Name: 
                </label>
                <input className='text' type="text" id='displayName' placeholder='Display Name' value={profile?.DisplayName ?? ''} onChange={(e) => updateProfileState({ DisplayName: e.target.value })}></input>
                <img className={element('field-icon')} src={EditIcon} alt={`Edit name Icon`} />
            </div>
            <div className={element('field', 'name')}>
                <label className='text' htmlFor='firstName'>
                    First Name: 
                </label>
                <input className='text' type="text" id='firstName' placeholder='First Name' value={profile?.FirstName ?? ''} onChange={(e) => updateProfileState({ FirstName: e.target.value })}></input>
                <img className={element('field-icon')} src={EditIcon} alt={`Edit name Icon`} />
            </div>
            <div className={element('field', 'name')}>
                <label className='text' htmlFor='lastName'>
                    Last Name: 
                </label>
                <input className='text' type="text" id='lastName' placeholder='Last Name' value={profile?.LastName ?? ''} onChange={(e) => updateProfileState({ LastName: e.target.value })}></input>
                <img className={element('field-icon')} src={EditIcon} alt={`Edit name Icon`} />
            </div>
            <div className={element('field', 'email')}>
                <label className='text' htmlFor='email'>
                    Communication Email: 
                </label>
                <input className='text'  type="text" id='email' placeholder='Email' value={profile?.Email ?? ''} onChange={(e) => updateProfileState({ Email: e.target.value })}></input>
                <img className={element('field-icon')} src={EditIcon} alt={`Edit email Icon`} />
            </div>

            <div className={element('field', 'phone')}>
                <label className='text' htmlFor='phone'>
                    Communication Phone: 
                </label>
                <input className='text' id='phone' type="text" placeholder='Phone' value={profile?.Phone ?? ''} onChange={(e) => updateProfileState({ Phone: e.target.value })}></input>
                <img className={element('field-icon')} src={EditIcon} alt={`Edit phone Icon`} />
            </div>

            <div className={element('field', 'communication')}>
                <label className='text'>Preferred Communication:</label>
                <div className={element('radio-group')}>
                    <div className={element('radio', 'sms')}>
                        <input id='preferSms' type="radio" checked={profile?.PreferredCommunicationType === 1} onChange={(e) => updateProfileState({ PreferredCommunicationType: 1 })}></input>
                        <label htmlFor='preferSms'>
                            SMS 
                        </label>
                    </div>
                    <div className={element('radio', 'email')}>
                        <input id='preferEmail' type="radio" checked={profile?.PreferredCommunicationType === 2} onChange={(e) => updateProfileState({ PreferredCommunicationType: 2 })}></input>
                        <label htmlFor='preferEmail'>
                            Email 
                        </label>
                    </div>
                    <div className={element('radio', 'optout')}>
                        <input id='preferNone' type="radio" checked={profile?.PreferredCommunicationType === 3} onChange={(e) => updateProfileState({ PreferredCommunicationType: 3 })}></input>
                        <label htmlFor='preferNone'>
                            Opt-Out 
                        </label>
                    </div>
                </div>
            </div>

            <div className={element('field', 'address')}>
                <label className='text' htmlFor='address'>
                    Address: 
                </label>
                <input className='text' id='address' type="text" placeholder='Address' value={profile?.Address ?? ''} onChange={(e) => updateProfileState({ Address: e.target.value })}></input>
                <img className={element('field-icon')} src={EditIcon} alt={`Edit address Icon`} />
            </div>
            

        </div>
    );
}

export default ProfileInfo;