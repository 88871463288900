import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'
import Card from "../../Components/Card";
import FileUploader from "../../Components/FileUploader";
import Button from "../../Components/Button";
import LinkButton from "../../Components/LinkButton";
import ProgressBar from "../../Components/ProgressBar";
import Loader from "../../Components/Loader";
import useBEM from "../../useBEM";
import api from '../../api';
import "./AdminPage.scss";

function AdminEventDashboard() {
    const { organizationId } = useParams();
    const [block, element] = useBEM('admin-page');
    const [organization, setOrganization] = useState();
    const [events, setEvents] = useState([]);
    //const [visibleEvents, setVisibleEvents] = useState([]);
    //const [lowerBoundEvent, setLowerBoundEvent] = useState(0);
    //const [upperBoundEvent, setUpperBoundEvent] = useState(0);
    const [isReady, setIsReady] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    //const pageSize = 5;

    useEffect(() => {
        getEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    const getEvents = async () => {
        setIsReady(false);
        var organizations = await api.GetOrganiazationsForUser();
        var organization = organizations.find(o => o.OrganizationId === organizationId);
        setOrganization(organization);
        if (organization) {
            var events = await api.GetEventsForOrganization(organization.OrganizationId);
            setEvents(events);
        }
        setIsReady(true);
    }

    const motorsportRegSync = async () => {
        setIsReady(false);
        var success = await api.ImportEventsFromMotorsportRegForOrganization(organizationId);
        if (!success) {
            alert('Import Error');
        }
        await getEvents();
    }

    const onFileUpload = async (file) => {
        setIsReady(false);
        api.ImportEventsForOrganization(file, organizationId)
        .then(() => {
            setEvents([]);
            setOrganization(null);
            setOrganization(organization);
            getEvents(); 
        }).catch((ex) => {
            console.error(ex);
            alert('Import Failure');
        });
    }

    const publishEvent = async (eventId) => {
        setIsBusy(true);
        var success = await api.PublishEvalsForEvent(eventId, organizationId);
        if (!success) {
            alert('Publishing Error');
        } else {
            alert('Publishing Success');
        }
        setIsBusy(false);
    }

    return (
        <div className={block()}>
            <h1 className={element('title')}>Events Dashboard</h1>
            <Link to="/admin/" className={element('back')}>Back</Link>

            <Loader isLoading={!isReady}>

                <Card>
                    <div className={element('commands')}>
                        <Button onClick={() => window.open(api.GetSampleImportEventsUrl(), '_blank')} label='Sample Upload' kind={'admin'} />
                        <FileUploader label='Upload' kind={'admin'} onFileSelect={onFileUpload} />
                        {organization?.MotorSportRegId && ( <Button onClick={motorsportRegSync} kind={'admin'} label='MotorsportReg Sync'></Button> )}
                        <LinkButton to={`/admin/${organizationId}/search`} kind={'admin'} label={'User Search'} />
                    </div>
                </Card>

                <div className={element('events')}>
                    {events.map((event) => {
                        return (
                            <div key={event.EventId}>
                                <Card>
                                    <h3 className={element('card-title')}>
                                        <Link to={`/admin/${organization.OrganizationId}/event/${event.EventId}`}>{event.Name}</Link>
                                    </h3>
                                    <div className={element('commands')}>
                                        <LinkButton to={`/admin/${organization.OrganizationId}/event/${event.EventId}/roster`} kind={'admin'} label={'Roster'} />
                                        <LinkButton to={`/admin/${organization.OrganizationId}/event/${event.EventId}/report`} kind={'admin'} label={'Report'} />
                                        {event.Published && (
                                            <Button disabled={isBusy} onClick={() => publishEvent(event.EventId)} kind={'admin'} label='Send Reminders'></Button>
                                        )}
                                        
                                        <ProgressBar numerator={event?.EvalsCompleted} denominator={event?.EvalsSent} labelTop='Completed Percentage' labelBottom={`${event?.EvalsCompleted} completed of ${event?.EvalsSent} sent`} />
                                    </div>
                                </Card>
                            </div>
                        );
                    })}
                </div>
            </Loader>
        </div>
    );
}

export default AdminEventDashboard;