import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'
import Card from "../../Components/Card";
import FileUploader from "../../Components/FileUploader";
import Button from "../../Components/Button";
import ProgressBar from "../../Components/ProgressBar";
import Loader from "../../Components/Loader";
import useBEM from "../../useBEM";
import api from '../../api';
import "./AdminPage.scss";
import ToggleButton from '../../Components/ToggleButton';
import ProfilePicture from '../../Components/ProfilePicture';
import StudentCard from '../../Components/StudentCard';

function AdminEventPage(props) {
    const { organizationId, eventId } = useParams();
    const [block, element] = useBEM('admin-page');
    const [eventModel, setEvent] = useState();
    const [evals, setEvals] = useState();
    const [group, setGroup] = useState('');
    const [groups, setGroups] = useState([]);
    const [option, setOption] = useState(props.start ?? 'Stats');

    const [isBusy, setIsBusy] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [isEvalsReady, setIsEvalsReady] = useState(false);

    const [isDirty, setIsDirty] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);
    const [userName,setUserName]=useState('');
    const [userEmail,setUserEmail]=useState('');
    const [userVehicle,setUserVehicle]=useState('');
    const [userTeacher, setUserTeacher]=useState(false);

    useEffect(() => {
        getEvent();
        getEventEvals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, eventId]);

    const getEvent = async () => {
        if (organizationId && eventId) {
            setIsReady(false);
            var event = await api.GetEvent(eventId, organizationId);
            setEvent(event);

            let groups = [];
            event?.Attendees.forEach((a) => {
                if (a.User.Group && !groups.find(g=>g === a.User.Group)) {
                    groups.push(a.User.Group);
                }
            });
            groups = groups.sort((a, b) => {
                return a?.toLowerCase() > b?.toLowerCase() ? 1 : -1;
            }); 

            setGroups(groups);
            setGroup(groups.length > 0 ? groups[0] : '');

            setIsReady(true);
        }
    }

    const getEventEvals = async () => {
        if (organizationId && eventId) {
            setIsEvalsReady(false);
            var eventEvals = await api.GetEvalsForEvent(eventId, organizationId);

            setEvals(eventEvals);

            setIsEvalsReady(true);
        }
    }

    const motorsportRegSync = async () => {
        setIsBusy(true);
        setIsReady(false);
        var success = await api.ImportEventFromMotorsportRegForOrganization(organizationId, eventId);
        if (!success) {
            alert('Import Error');
        }
        await getEvent();
        setIsBusy(false);
        setIsReady(true);
    }

    const publishEvent = async () => {
        setIsBusy(true);
        setIsReady(false);
        var success = await api.PublishEvalsForEvent(eventId, organizationId);
        if (!success) {
            alert('Publishing Error');
        } else {
            alert('Publishing Success');
        }
        setIsBusy(false);
        setIsReady(true);
    }

    const sendWelcomeEmails = async () => {
        setIsBusy(true);
        setIsReady(false);
        var success = await api.SendWelcomeEmailsForEvent(eventId, organizationId);
        if (!success) {
            alert('Welcome Messages Error');
        } else {
            alert('Welcome Messages Success');
        }
        setIsBusy(false);
        setIsReady(true);
    }

    const onFileUpload = async (file) => {
        api.ImportAssignmentsForEvent(file, eventModel.EventId, organizationId)
        .then(() => {

        }).catch((ex) => {
            console.error(ex);
            alert('Import Failure');
        });
    }
    
    const onInstructorChange = (userEmail, instructorEmail) => {
        const instructor = eventModel.Attendees.find(x=>x.User.UserEmail === instructorEmail);
        const user = eventModel.Attendees.find(x=>x.User.UserEmail === userEmail);
        if (!instructor && user) {
            user.Instructor = null;
            const attendees = [...eventModel.Attendees.filter(x=>x.User.UserEmail !== userEmail), user];
            setEvent({...eventModel, Attendees: attendees});
            setIsDirty(true);
        }
        else if (instructor && instructor.User && user) {
            const instructorUser = instructor.User;
            user.Instructor = instructorUser;
            const attendees = [...eventModel.Attendees.filter(x=>x.User.UserEmail !== userEmail), user];
            setEvent({...eventModel, Attendees: attendees});
            setIsDirty(true);
        }
    }

    const onGroupChange = (userEmail, group) => {
        const user = eventModel.Attendees.find(x=>x.User.UserEmail === userEmail);
        if (user) {
            user.User.Group = group;
            const attendees = [...eventModel.Attendees.filter(x=>x.User.UserEmail !== userEmail), user];
            setEvent({...eventModel, Attendees: attendees});
            setIsDirty(true);
        }

    }

    const getDisplayName = (user) => {
        if (user.User.FirstAndLastName?.trim() && user.User.DisplayName?.trim() && user.User.FirstAndLastName?.trim() !== user.User.DisplayName?.trim()) {
            return `${user.User.DisplayName} (${user.User.FirstAndLastName})`;
        }
        else if (user.User.FirstAndLastName?.trim()) {
            return user.User.FirstAndLastName;
        } 
        else if (user.User.DisplayName?.trim()) {
            return user.User.DisplayName;
        }
        else {
            return user.User.UserEmail;
        }
    };

    const sortUsers = (a, b) => {
        const aValue = getDisplayName(a);
        const bValue = getDisplayName(b);
        return aValue.toLowerCase() > bValue.toLowerCase() ? 1 : -1;
    }

    //Sort/filter attendees
    let attendees = eventModel?.Attendees ?? [];
    if (group) {
        attendees = attendees.filter((a => a.User.Group === group));
    }
    attendees = attendees.sort(sortUsers);

    //sort/filter instructors
    let instructors = eventModel?.Attendees ?? [];
    if (groups.find(g => g === 'Instructor')) {
        instructors = instructors.filter((i) => i.User.Group === 'Instructor')
    }
    instructors = instructors.sort(sortUsers);
    const instructorOptions = instructors?.map((instructor) => {
        return { label: getDisplayName(instructor), value: instructor.User.UserEmail };
    }) ?? [];
    instructorOptions.unshift({label: 'None', value: ''});

    const groupOptions = groups.map((group) => {
        return { label: group, value: group };
    });

    const addUser = () => {
        if (userName && userEmail && userVehicle) {
            const updatedEvent = { ...eventModel };
            if (!userTeacher) {
                updatedEvent.Attendees.push({
                    User: {
                        UserEmail: userEmail,
                        UserName: userName,
                        Group: 'Novice'
                    },
                    Vehicle: userVehicle,
                });
            } else {
                updatedEvent.Attendees.push({
                    User: {
                        UserEmail: userEmail,
                        UserName: userName,
                        Group: 'Instructor'
                    },
                    Vehicle: userVehicle,
                });
            }
            setUserEmail('');
            setUserName('');
            setUserVehicle('');
            setUserTeacher(false);
            setShowAddUser(false);
            setIsDirty(true);
            setEvent(updatedEvent);
        } else {
            alert('Set user name, email, and vehicle.')
        }
    }

    const saveEvent = () => {
        setIsBusy(true);
        api.UpdateEvent(organizationId, eventModel);
        setIsDirty(false);
        setIsBusy(false);
    }

    const saveEventAttendees = () => {
        setIsBusy(true);
        api.UpdateEventAttendees(organizationId, eventModel);
        setIsDirty(false);
        setIsBusy(false);
    }
    
    return (
        <div className={block()}>

            <h1 className={element('title')}>Event Dashboard</h1>
            <h2 className={element('title')}>{eventModel?.Name}</h2>
            <Link to={`/admin/${organizationId}`} className={element('back')}>Back</Link>

            <ToggleButton options={['Stats', 'Roster', 'Settings']} onClick={(option) => setOption(option)} selected={option} />

            <Loader isLoading={!isReady}>
                {option === 'Settings' && (
                    <>
                        <Card>
                            <div className={element('form')}>
                                <div className={element('field')}>
                                    <label className={element('label')} htmlFor='eventName'>Name</label>
                                    <input className={element('input')} id='eventName' value={eventModel?.Name} onChange={(e) => setEvent({...eventModel, Name: e.target.value})} />
                                </div>
                                <div className={element('field')}>
                                    <label className={element('label')} htmlFor='eventDate'>Date</label>
                                    <input className={element('input')} id='eventDate' type='date' value={eventModel?.Start} onChange={(e) => setEvent({...eventModel, Start: e.target.value})} />
                                </div>
                                <div className={element('field')}>
                                    <label className={element('label')} htmlFor='eventTrack'>Track</label>
                                    <input className={element('input')} id='eventTrack' value={eventModel?.Location?.Name} onChange={(e) => setEvent({...eventModel, Location: {...eventModel.Location, Name: e.target.value}})} />
                                </div>
                                <div className={element('save')}>
                                    <Button disabled={isBusy} onClick={saveEvent} kind={'admin'} label='Save'></Button>
                                </div>
                            </div>
                        </Card>
                    </>
                )}
                {option === 'Stats' && (
                    <>
                        <Card>
                            <ProgressBar numerator={eventModel?.EvalsCompleted} denominator={eventModel?.EvalsSent} labelTop='Completed Percentage' labelBottom={`${eventModel?.EvalsCompleted} completed of ${eventModel?.EvalsSent} sent`} />
                        </Card>

                        <h3 className={element('title')}>Pending Evals</h3>

                        <Loader isLoading={!isEvalsReady}>
                            <div className={element('slide')}>
                                {evals && evals.filter(x=>x.EvalModel.EvalState < 3).map((evalWithProfile, i) => {
                                    return (
                                        <StudentCard key={i} evalWithProfile={evalWithProfile} />
                                    );
                                })}
                            </div>
                        </Loader>

                        <h3 className={element('title')}>Completed Evals</h3>

                        <Loader isLoading={!isEvalsReady}>
                            <div className={element('slide')}>
                                {evals && evals.filter(x=>x.EvalModel.EvalState === 3).map((evalWithProfile, i) => {
                                    return (
                                        <StudentCard key={i} evalWithProfile={evalWithProfile} link={`/admin/${evalWithProfile.EvalModel.OrganizationId}/eval/${evalWithProfile.EvalModel.EvalId}`} />
                                    );
                                })}
                            </div>
                        </Loader>

                    </>
                )}

                {option === 'Roster' && (
                    <>
                        <h3 className={element('title')}>Roster</h3>

                        <Card>
                            <div className={element('commands')}>
                                <Button onClick={() => window.open(api.GetSampleImportAssignmentsUrl(), '_blank')} label='Sample Upload' kind={'admin'} />
                                <FileUploader label='Upload' kind={'admin'} onFileSelect={onFileUpload} />
                                {eventModel?.IntegrationIdentifier?.Data?.MotorsportReg && (
                                    <Button disabled={isBusy} onClick={motorsportRegSync} kind={'admin'} label='MotorsportReg Sync'></Button> 
                                )}
                                <Button disabled={isBusy} onClick={sendWelcomeEmails} kind={'admin'} label='Welcome Emails'></Button>
                                <Button disabled={isBusy} onClick={publishEvent} kind={'admin'} label='Publish'></Button>
                                <Button disabled={isBusy} onClick={() => setShowAddUser(!showAddUser)} kind={'admin'} label='Add User'></Button>
                                {isDirty && (
                                    <Button disabled={isBusy}  onClick={saveEventAttendees} kind={'admin'} label='Save'></Button>
                                )}
                            </div>
                        </Card>

                        <ToggleButton options={groups} onClick={(option) => setGroup(option)} selected={group} />

                        {showAddUser && (
                            <Card>
                                <div className={element('form')}>
                                    <div className={element('field')}>
                                        <label className={element('label')} htmlFor='userName'>Name</label>
                                        <input className={element('input')} id='userName' value={userName} onChange={(e) => setUserName(e.target.value)} />
                                    </div>
                                    <div className={element('field')}>
                                        <label className={element('label')} htmlFor='userEmail'>Email</label>
                                        <input className={element('input')} id='userEmail' value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
                                    </div>
                                    <div className={element('field')}>
                                        <label className={element('label')} htmlFor='userVehicle'>Vehicle</label>
                                        <input className={element('input')} id='userVehicle' value={userVehicle} onChange={(e) => setUserVehicle(e.target.value)} />
                                    </div>
                                    <div className={element('field-radio')}>
                                        <label className={element('label')} htmlFor='userStudent'>Role</label>
                                        <input className={element('input-radio')} id='userStudent' checked={userTeacher === false} onChange={(e) => setUserTeacher(false)} type='radio' radioGroup='userTeacher' />
                                        <label className={element('label-radio')} htmlFor='userStudent'>Student</label>
                                        <input className={element('input-radio')} id='userTeacher' checked={userTeacher === true} onChange={(e) => setUserTeacher(true)} type='radio' radioGroup='userTeacher' />
                                        <label className={element('label-radio')} htmlFor='userTeacher'>Teacher</label>
                                    </div>
                                    <div className={element('save')}>
                                        <Button onClick={() => addUser()} kind={'admin'} label='Add'></Button>
                                    </div>
                                </div>
                            </Card>
                        )}

                        <div className={element('attendees')}>
                            {attendees?.map((attendee, index) => {
                                return (
                                    <div key={index} className={element('attendee')}>
                                        <Card>
                                            <div className={element('attendee-picture')}>
                                                <ProfilePicture picture={attendee.User.ProfilePictureUrl} />
                                            </div>
                                            <div className={element('attendee-info')}>
                                                <div className={element('attendee-name')}>{getDisplayName(attendee)}</div>
                                                <div className={element('attendee-vehicle')}>{attendee.Vehicle}</div>
                                            </div>
                                        </Card>
                                        <div className={element('attendee-options')}>
                                            <div className={element('attendee-option')}>
                                                <label className={element('attendee-options-label')} htmlFor={'group-' + index}>Group</label>
                                                <select 
                                                    id={'group-' + index}
                                                    value={attendee.User.Group}
                                                    onChange={(e) => onGroupChange(attendee.User.UserEmail, e.target.value)}
                                                    className={element('attendee-options-select')}>
                                                    {groupOptions.map((option) => {
                                                        return (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className={element('attendee-option')}>
                                                <label className={element('attendee-options-label')} htmlFor={'instructor-' + index}>Instructor</label>
                                                <select 
                                                    id={'instructor-' + index}
                                                    value={attendee.Instructor?.UserEmail ?? ''}
                                                    onChange={(e) => onInstructorChange(attendee.User.UserEmail, e.target.value)}
                                                    className={element('attendee-options-select')}>
                                                    {instructorOptions.map((option) => {
                                                        return (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                    </>
                )}

            </Loader>

        </div>
    );
}

export default AdminEventPage;