export const fieldTypes = Object.freeze({
    SLIDER: 'SLIDER',
    TEXTAREA: 'TEXTAREA'
});

const validateSection = (sectionDefinition, evaluation, section) => {
    const errors = [];
    for (const field of sectionDefinition.fields) {
        switch (field.type) {
            case fieldTypes.SLIDER: {
                if (field.optional === true || (!evaluation[section] && !evaluation[section][field.property])) {
                    errors.push({
                        section: section,
                        field: field,
                        message: 'Selection Required!'
                    });
                }
                let value = evaluation[section][field.property];

                const optionValues = field.options.map(x=>x.value);
                const max = Math.max.apply(null, optionValues);
                const min = Math.min.apply(null, optionValues);
                if (value < min || value > max) {
                    errors.push({
                        section: section,
                        field: field,
                        message: 'Invalid Value!'
                    });
                }
                break;
            }
            default: {
                break;
            }
        }
    }
    return errors;
}

const validateAllSections = (contentDefinition, evaluation, section) => {
    for (const sectionDefinition in contentDefinition.sections) {
        validateSection(sectionDefinition, evaluation, section);
    }
}

export const validate = (contentDefinition, evaluation, section) => {
    if (section) {
        const sectionDefinition = contentDefinition.sections.find(x=>x.section === section);
        if (sectionDefinition) {
            return validateSection(sectionDefinition, evaluation, section);
        } else {
            validateAllSections(contentDefinition, evaluation, section);
        }
    } else {
        validateAllSections(contentDefinition, evaluation, section);
    }
}

export const evaluationTypes = {
    INSTRUCTOR: 1,
    STUDENT: 2
}

export const getAnswerFromEvaluation = (evaluation, sectionProperty, fieldProperty) => {
    if (!evaluation.Answers) {
        evaluation.Answers = {};
    }

    if (sectionProperty && !evaluation.Answers[sectionProperty]) {
        evaluation.Answers[sectionProperty] = {};
    }

    if (sectionProperty && fieldProperty && evaluation.Answers[sectionProperty] && evaluation.Answers[sectionProperty][fieldProperty]) {
        return evaluation.Answers[sectionProperty][fieldProperty];
    } else if (!sectionProperty && fieldProperty && evaluation.Answers[fieldProperty]) {
        return evaluation.Answers[fieldProperty]; 
    }

    return null;
}

export const getSummary = (template, evalModel) => {
    let html = "<p>";
    for (const section of template.sections) {
        html += `<span style='font-weight: bold; font-size: 1.2rem; text-decoration: underline'>${section.title}</span><br>`;

        for (const field of section.fields) {
            // if (field.private === true) {
            //     continue;
            // }

            const answer = getAnswerFromEvaluation(evalModel, section.property, field.property);
            let answerText = answer;
            if (field.options) {
                answerText = field.options.find(x=>x.value === answer)?.description ?? 'N/A';
            }
            html += `<span style='font-weight: bold;'>${field.label}: ${answer === answerText ? '' : answer}</span> <span>${answerText}</span><br>`;
        }
        html += '<br><br>';
    }
    html += "</p>"
    if (evalModel.PublicLinkKey) {
        html += `<p><a href='${process.env.REACT_APP_LINK}/eval/link/${evalModel.PublicLinkKey}'>View in Apex Evals</a></p>`
    }
    
    return html;
}

export const getSectionScore = (section, evalModel) => {
    let count = 0;
    let total = 0;
    for (const field of section.fields) {
        const answer = getAnswerFromEvaluation(evalModel, section.property, field.property);
        const answerInt = parseInt(answer);
        if (answerInt && answerInt >= 0) {
            total += answerInt;
            count ++;
        }
    }
    if (count > 0) {
        return total / count;
    } else {
        return -1;
    }    
}

export const getApexScore = (template, evalModel) => {
    let count = 0;
    let total = 0;
    for (const section of template.sections) {
        const sectionScore = getSectionScore(section, evalModel);
        if (sectionScore >= 0) {
            total += sectionScore;
            count++;
        }
    }
    if (count > 0) {
        return total / count;
    } else {
        return -1;
    }   
}

export const saveAnswerToEvaluation = (evaluation, sectionProperty, fieldProperty, value) => {
    const updatedEval = {...evaluation, LastUpdated: new Date().toISOString() };

    if (!updatedEval.Answers) {
        updatedEval.Answers = {};
    }

    if (sectionProperty && !updatedEval.Answers[sectionProperty]) {
        updatedEval.Answers[sectionProperty] = {};
    }

    if (sectionProperty && fieldProperty) {
        updatedEval.Answers[sectionProperty][fieldProperty] = value;
    } else if (!sectionProperty && fieldProperty) {
        updatedEval.Answers[fieldProperty] = value; 
    }

    return updatedEval;
}

export const getEvaluationContent = (evalType) => {
    switch (evalType) {
        case 0:
            return studentEvaluationContent();
        case 1:
            return instructorEvaluationContent();
        default:
            return null;
    }
}

export const instructorEvaluationContent = () => {
    return {
        type: 'instructor',
        sections: [
            {
                property: 'Vision',
                title: 'Vision',
                description: 'Rate your student using the scale below. 1 is novice level and 4 is advanced. After selection a brief description will be shown with what it entails.',
                fields: [
                    {
                        property: 'LookingAhead',
                        label: 'Looking Ahead',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Only sees directly in front of car.'
                            },
                            {
                                value: 2,
                                description: 'Hyper focused on next input, cannot string multiple inputs together.'
                            },
                            {
                                value: 3,
                                description: 'Starting to have wide screen vision with eyes up to see beyond next input point.'
                            },
                            {
                                value: 4,
                                description: 'Looks through corners and consistently looks ahead for problems.'
                            },
                        ]
                    },
                    {
                        property: 'SituationalAwareness',
                        label: 'Situational Awareness',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Cannot see beyond what is immediately ahead of them.'
                            },
                            {
                                value: 2,
                                description: 'Recognizes some but not all passing cars but does not set up for passes or passer.'
                            },
                            {
                                value: 3,
                                description: 'Recognizes all passing cars and beginning to understand how to mitigate upcoming problems.'
                            },
                            {
                                value: 4,
                                description: 'Sees 360 degrees around the car and knows exactly where other cars are in close proximity.'
                            },
                        ]
                    },
                    {
                        property: 'FlagStations',
                        label: 'Flag Stations',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Cannot recognize Flag Stations or Flags themselves.'
                            },
                            {
                                value: 2,
                                description: 'Missing flags but has some understanding of flags.'
                            },
                            {
                                value: 3,
                                description: 'Understands all flags and should not miss any  including not running checkered.'
                            },
                            {
                                value: 4,
                                description: 'Can call out all flag stations, understands each flag type and does not miss flag notifications.'
                            },
                        ]
                    },
                    {
                        property: 'WaypointUsage',
                        label: 'Waypoint Usage',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Unaware of track waypoints.'
                            },
                            {
                                value: 2,
                                description: 'Looks for reference points, still struggles with consistency.'
                            },
                            {
                                value: 3,
                                description: 'Recognizes and can point out reference points and uses waypoints like brake markers.'
                            },
                            {
                                value: 4,
                                description: 'Can talk through a lap and drive consistently while pointing off waypoints both on track and in horizon.'
                            },
                        ]
                    }
                ]
            },
            {
                property: 'TrackUse',
                title: 'Track Use',
                description: 'Rate your student using the scale below. 1 is novice level and 4 is advanced. After selection a brief description will be shown with what it entails.',
                fields: [
                    {
                        property: 'UseOfApexes',
                        label: 'Use of Apexes',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'No understanding of apex types and misses apex consistently.'
                            },
                            {
                                value: 2,
                                description: 'Beginning to hit apexes inconsistently but does not understand how or when to use different types.'
                            },
                            {
                                value: 3,
                                description: 'Understands early and late apexes and shows consistency.'
                            },
                            {
                                value: 4,
                                description: 'Flows well between combination corners and understands displacement and sacrificial apexes.'
                            },
                        ]
                    },
                    {
                        property: 'TrackWidthUsage',
                        label: 'Track Width Usage',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Does not set up or track out correctly for turns.'
                            },
                            {
                                value: 2,
                                description: 'Early turn in or crabbing is common, not tracking out to the width of the track.'
                            },
                            {
                                value: 3,
                                description: 'Sets up wide and tracks out comfortably to curbs or walls.'
                            },
                            {
                                value: 4,
                                description: 'Shows precision corner entry and exit. Does not torture tires to reach certain corner corrections.'
                            },
                        ]
                    },
                    {
                        property: 'BrakeTiming',
                        label: 'Brake Timing',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Braking is soft and early, also used reactively when scared.'
                            },
                            {
                                value: 2,
                                description: 'Conservative brake application but beginning to show consistency.'
                            },
                            {
                                value: 3,
                                description: 'Hard brake application with early trail-braking usage.'
                            },
                            {
                                value: 4,
                                description: 'Understands braking for weight control of the vehicle with consistent trail-braking all the way into an apex.'
                            },
                        ]
                    },
                    {
                        property: 'DrivingLine',
                        label: 'Driving Line',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Does not know the track or where to be, when.'
                            },
                            {
                                value: 2,
                                description: 'Shows some line knowledge but still needs feedback to be precise.'
                            },
                            {
                                value: 3,
                                description: 'Drives line consistently and comfortable off line in corners.'
                            },
                            {
                                value: 4,
                                description: 'Can show and tell why you enact a certain line for a track.  Comfortable in any entry or exit point.'
                            },
                        ]
                    }

                ]
            },
            {
                property: 'Inputs',
                title: 'Inputs',
                description: 'Rate your student using the scale below. 1 is novice level and 4 is advanced. After selection a brief description will be shown with what it entails.',
                fields: [
                    {
                        property: 'Throttle',
                        label: 'Throttle',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Sudden and abrupt usage.'
                            },
                            {
                                value: 2,
                                description: 'Shows some signs of linear throttle application but no understanding of when throttle is necessary.'
                            },
                            {
                                value: 3,
                                description: 'Controlled application coming out of a corner and uses it to help rotate for weight control of the car.'
                            },
                            {
                                value: 4,
                                description: 'Maximizes early throttle application with slip angles for peak speed.'
                            },
                        ]
                    },
                    {
                        property: 'Steering',
                        label: 'Steering',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Jerky and abrupt along with improper hand placement.'
                            },
                            {
                                value: 2,
                                description: 'Beginning to turn in smooth but inconsistent. Needs instruction and reminders on when to open hands.'
                            },
                            {
                                value: 3,
                                description: 'No unnecessary corrections or sawing mid corner and understands to open their hands as early as possible.'
                            },
                            {
                                value: 4,
                                description: 'Relaxed grip with precise use of wheel to control weight balance and tire life.'
                            },
                        ]
                    },
                    {
                        property: 'WeightControl',
                        label: 'Weight Control',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'No understanding and unable to show due to slow speeds.'
                            },
                            {
                                value: 2,
                                description: 'Able to recognize under and oversteer but still has little to no understand of how to mitigate using inputs.'
                            },
                            {
                                value: 3,
                                description: 'Understands brake and throttle to control mid corner rotation of the car.'
                            },
                            {
                                value: 4,
                                description: 'Can use both throttle and brakes for weight balance and understands how to applies to slip angles.'
                            },
                        ]
                    },
                    {
                        property: 'ShiftTimingControl',
                        label: 'Shift Timing / Control',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 'N/A',
                                description: 'Not Applicable'
                            },
                            {
                                value: 1,
                                description: 'Only uses 1 gear throughout track and does not understand when to shift manually.'
                            },
                            {
                                value: 2,
                                description: 'Uses multiple gears but jerky up and down shifts upsets balance of the car and inconsistent each lap.'
                            },
                            {
                                value: 3,
                                description: 'Uses gears consistently and up and down shifts are smooth in correct places on track. Efficient heel-toe.'
                            },
                            {
                                value: 4,
                                description: 'Precise use of gearing along the understanding of up and down shifts to RPMs effecting weight balance.'
                            },
                        ]
                    }

                ]
            },
            {
                property: 'Consistency',
                title: 'Consistency',
                description: 'Rate your student using the scale below. 1 is novice level and 4 is advanced. After selection a brief description will be shown with what it entails.',
                fields: [
                    {
                        property: 'LapToLap',
                        label: 'Lap-to-lap',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'No two laps are alike.'
                            },
                            {
                                value: 2,
                                description: 'Unable to string a full session of similar laps. One or two similar outliers in a full track session.'
                            },
                            {
                                value: 3,
                                description: 'Can flow through an entire session consistently, recognizes when inconsistencies happen.'
                            },
                            {
                                value: 4,
                                description: 'Even at maximum effort they are very consistent.'
                            },
                        ]
                    },
                    {
                        property: 'Pace',
                        label: 'Pace',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Slow throughout all aspects of the track.'
                            },
                            {
                                value: 2,
                                description: 'Slow through corners but has some confidence in the straight aways.'
                            },
                            {
                                value: 3,
                                description: 'Can keep up with intermediate / advanced drivers in similar vehicles. No issues in traffic or awareness.'
                            },
                            {
                                value: 4,
                                description: 'Comfortable experimenting without losing pace.'
                            },
                        ]
                    },
                    {
                        property: 'MentalAcuity',
                        label: 'Mental Acuity',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Uneasy and uncertain of themselves and the car.'
                            },
                            {
                                value: 2,
                                description: 'Traffic causes driving apprehension but otherwise undisturbed.'
                            },
                            {
                                value: 3,
                                description: 'Undisturbed in high traffic situations and being passed or held up by other drivers.'
                            },
                            {
                                value: 4,
                                description: 'Comfortable in close proximity to other cars and never has “red-mist” when challenged.'
                            },
                        ]
                    },
                    {
                        property: 'SelfDiagnosis',
                        label: 'Self Diagnosis',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'No understanding of how to critique a lap.'
                            },
                            {
                                value: 2,
                                description: 'Early signs of early braking and turn in but little to know knowledge of much else.'
                            },
                            {
                                value: 3,
                                description: 'Can self-critique with understanding not only how but why something happened.'
                            },
                            {
                                value: 4,
                                description: 'Intrinsic self analysis with the ability to help others and test theories to faster opportunities.'
                            },
                        ]
                    }
                ]
            },
            {
                property: '',
                title: 'Open Forms',
                description: '',
                fields: [
                    {
                        property: 'PrivateNotes',
                        label: 'Internal Notes',
                        private: true,
                        type: fieldTypes.TEXTAREA,
                    },
                    {
                        property: 'PublicNotes',
                        label: 'External Notes for Student',
                        type: fieldTypes.TEXTAREA,
                    }
                ]
            },
        ],
        updateEvalModel: (evalModel) => {
            return evalModel;
        }
    };
}

export const studentEvaluationContent = () => {
    return {
        type: 'student',
        sections: [
            {
                property: 'Communication',
                title: 'Communication',
                description: 'Rate your instructor using the scale below. 1 is not meeting expectations and 4 is the best. After selection a brief description will be shown with what it entails.',
                fields: [
                    {
                        property: 'Professional',
                        label: 'Professional & Polite',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Did not meet quality expectation.'
                            },
                            {
                                value: 2,
                                description: 'Met Expectation.'
                            },
                            {
                                value: 3,
                                description: 'Exceeded Expectations.'
                            },
                            {
                                value: 4,
                                description: 'One of the Best instructors I\'ve ever had.'
                            },
                        ]
                    },
                    {
                        property: 'Comfortable',
                        label: 'Made you comfortable',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Did not meet quality expectation.'
                            },
                            {
                                value: 2,
                                description: 'Met Expectation.'
                            },
                            {
                                value: 3,
                                description: 'Exceeded Expectations.'
                            },
                            {
                                value: 4,
                                description: 'One of the Best instructors I\'ve ever had.'
                            },
                        ]
                    },
                    {
                        property: 'Punctuality',
                        label: 'Punctuality',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Did not meet quality expectation.'
                            },
                            {
                                value: 2,
                                description: 'Met Expectation.'
                            },
                            {
                                value: 3,
                                description: 'Exceeded Expectations.'
                            },
                            {
                                value: 4,
                                description: 'One of the Best instructors I\'ve ever had.'
                            },
                        ]
                    },
                    {
                        property: 'Direction',
                        label: 'Clear and Concise Direction',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Did not meet quality expectation.'
                            },
                            {
                                value: 2,
                                description: 'Met Expectation.'
                            },
                            {
                                value: 3,
                                description: 'Exceeded Expectations.'
                            },
                            {
                                value: 4,
                                description: 'One of the Best instructors I\'ve ever had.'
                            },
                        ]
                    }
                ]
            },
            {
                property: 'Instruction',
                title: 'Instruction',
                description: 'Rate your instructor using the scale below. 1 is not meeting expectations and 4 is the best. After selection a brief description will be shown with what it entails.',
                fields: [
                    {
                        property: 'Goals',
                        label: 'Goal Setting / Achieving',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Did not meet quality expectation.'
                            },
                            {
                                value: 2,
                                description: 'Met Expectation.'
                            },
                            {
                                value: 3,
                                description: 'Exceeded Expectations.'
                            },
                            {
                                value: 4,
                                description: 'One of the Best instructors I\'ve ever had.'
                            },
                        ]
                    },
                    {
                        property: 'Knowledge',
                        label: 'Technical Knowledge ',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Did not meet quality expectation.'
                            },
                            {
                                value: 2,
                                description: 'Met Expectation.'
                            },
                            {
                                value: 3,
                                description: 'Exceeded Expectations.'
                            },
                            {
                                value: 4,
                                description: 'One of the Best instructors I\'ve ever had.'
                            },
                        ]
                    },
                    {
                        property: 'Education',
                        label: 'Education Value',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Did not meet quality expectation.'
                            },
                            {
                                value: 2,
                                description: 'Met Expectation.'
                            },
                            {
                                value: 3,
                                description: 'Exceeded Expectations.'
                            },
                            {
                                value: 4,
                                description: 'One of the Best instructors I\'ve ever had.'
                            },
                        ]
                    },
                    {
                        property: 'Debrief',
                        label: 'After session debrief quality',
                        type: fieldTypes.SLIDER,
                        options: [
                            {
                                value: 1,
                                description: 'Did not meet quality expectation.'
                            },
                            {
                                value: 2,
                                description: 'Met Expectation.'
                            },
                            {
                                value: 3,
                                description: 'Exceeded Expectations.'
                            },
                            {
                                value: 4,
                                description: 'One of the Best instructors I\'ve ever had.'
                            },
                        ]
                    }
                ]
            },
            {
                property: '',
                title: 'Open Forms',
                description: '',
                fields: [
                    {
                        property: 'PublicNotes',
                        label: 'External Notes for Instructor',
                        type: fieldTypes.TEXTAREA,
                    }
                ]
            },
        ],
        updateEvalModel: (evalModel) => {
            return evalModel;
        }
    };
}



export function GetVisionContent(evaluation, setValue) {
    return [
        // {
        //     value: evaluation?.Vision?.LookingAhead,
        //     setValue: (v) => setValue({LookingAhead: v}),
        //     label: "Looking Ahead",
        //     one: "Only sees directly in front of car.",
        //     two: "Hyper focused on next input, cannot string multiple inputs together.",
        //     three: "Starting to have wide screen vision with eyes up to see beyond next input point.",
        //     four: "Looks through corners and consistently looks ahead for problems. "
        // },
        // {
        //     value: evaluation?.Vision?.SituationalAwareness,
        //     setValue: (v) => setValue({SituationalAwareness: v}),
        //     label: "Situational Awareness",
        //     one: "Cannot see beyond what is immediately ahead of them.",
        //     two: "Recognizes some but not all passing cars but does not set up for passes or passer.",
        //     three: "Recognizes all passing cars and beginning to understand how to mitigate upcoming problems.",
        //     four: "Sees 360 degrees around the car and knows exactly where other cars are in close proximity."
        // },
        // {
        //     value: evaluation?.Vision?.FlagStations,
        //     setValue: (v) => setValue({FlagStations: v}),
        //     label: "Flag Stations",
        //     // one: "Cannot recognize Flag Stations or Flags themselves.",
        //     // two: "Missing flags but has some understanding of flags.",
        //     three: "Understands all flags and should not miss any  including not running checkered.",
        //     four: "Can call out all flag stations, understands each flag type and does not miss flag notifications."
        // },
        // {
        //     value: evaluation?.Vision?.WaypointUsage,
        //     setValue: (v) => setValue({WaypointUsage: v}),
        //     label: "Waypoint Usage",
        //     one: "Unaware of track waypoints.",
        //     two: "Looks for reference points, still struggles with consistency.",
        //     three: "Recognizes and can point out reference points and uses waypoints like brake markers.",
        //     four: "Can talk through a lap and drive consistently while pointing off waypoints both on track and in horizon."
        // },
    ];
}

export function getTrackUseContent(evaluation, setValue) {
    return [
        // {
        //     value: evaluation?.TrackUse?.UseOfApexes,
        //     setValue: (v) => setValue({UseOfApexes: v}),
        //     label: "Use of Apexes",
        //     one: "No understanding of apex types and misses apex consistently.",
        //     two: "Beginning to hit apexes inconsistently but does not understand how or when to use different types.",
        //     three: "Understands early and late apexes and shows consistency.",
        //     four: "Flows well between combination corners and understands displacement and sacrificial apexes.",
        // },
        // {
        //     value: evaluation?.TrackUse?.TrackWidthUsage,
        //     setValue: (v) => setValue({TrackWidthUsage: v}),
        //     label: "Track Width Usage",
        //     one: "Does not set up or track out correctly for turns.",
        //     two: "Early turn in or crabbing is common, not tracking out to the width of the track.",
        //     three: "Sets up wide and tracks out comfortably to curbs or walls.",
        //     four: "Shows precision corner entry and exit. Does not torture tires to reach certain corner corrections.",
        // },
        // {
        //     value: evaluation?.TrackUse?.BrakeTiming,
        //     setValue: (v) => setValue({BrakeTiming: v}),
        //     label: "Brake Timing",
        //     one: "Braking is soft and early, also used reactively when scared.",
        //     two: "Conservative brake application but beginning to show consistency.",
        //     three: "Hard brake application with early trail-braking usage.",
        //     four: "Understands braking for weight control of the vehicle with consistent trail-braking all the way into an apex.",
        // },
        // {
        //     value: evaluation?.TrackUse?.DrivingLine,
        //     setValue: (v) => setValue({DrivingLine: v}),
        //     label: "Driving Line",
        //     one: "Does not know the track or where to be, when.",
        //     two: "Shows some line knowledge but still needs feedback to be precise.",
        //     three: "Drives line consistently and comfortable off line in corners.",
        //     four: "Can show and tell why you enact a certain line for a track.  Comfortable in any entry or exit point.",
        // },
    ];
}

export function getInputsContent(evaluation, setValue) {
    
    
    return [
        // {
        //     value: evaluation?.Inputs?.Throttle,
        //     setValue: (v) => setValue({Throttle: v}),
        //     label: "Throttle",
        //     one: "Sudden and abrupt usage.",
        //     two: "Shows some signs of linear throttle application but no understanding of when throttle is necessary.",
        //     three: "Controlled application coming out of a corner and uses it to help rotate for weight control of the car.",
        //     four: "Maximizes early throttle application with slip angles for peak speed.",
        // },
        // {
        //     value: evaluation?.Inputs?.Steering,
        //     setValue: (v) => setValue({Steering: v}),
        //     label: "Steering",
        //     one: "Jerky and abrupt along with improper hand placement.",
        //     two: "Beginning to turn in smooth but inconsistent. Needs instruction and reminders on when to open hands.",
        //     three: "No unnecessary corrections or sawing mid corner and understands to open their hands as early as possible.",
        //     four: "Relaxed grip with precise use of wheel to control weight balance and tire life.",
        // },
        // {
        //     value: evaluation?.Inputs?.WeightControl,
        //     setValue: (v) => setValue({WeightControl: v}),
        //     label: "WeightControl",
        //     one: "No understanding and unable to show due to slow speeds.",
        //     two: "Able to recognize under and oversteer but still has little to no understand of how to mitigate using inputs.",
        //     three: "Understands brake and throttle to control mid corner rotation of the car.",
        //     four: "Can use both throttle and brakes for weight balance and understands how to applies to slip angles.",
        // },
        // {
        //     value: evaluation?.Inputs?.ShiftTimingControl,
        //     setValue: (v) => setValue({ShiftTimingControl: v}),
        //     label: "Shift Timing / Control",
        //     "N/A": "Not Applicable",
        //     one: "Only uses 1 gear throughout track and does not understand when to shift manually.",
        //     two: "Uses multiple gears but jerky up and down shifts upsets balance of the car and inconsistent each lap.",
        //     three: "Uses gears consistently and up and down shifts are smooth in correct places on track. Efficient heel-toe.",
        //     four: "Precise use of gearing along the understanding of up and down shifts to RPMs effecting weight balance.",
        // },
    ];
}

export function getConsistencyContent(evaluation, setValue) {
    return [
        {
            value: evaluation?.Consistency?.LapToLap,
            setValue: (v) => setValue({LapToLap: v}),
            label: "Lap-to-lap",
            one: "No two laps are alike",
            two: "Unable to string a full session of similar laps. One or two similar outliers in a full track session.",
            three: "Can flow through an entire session consistently, recognizes when inconsistencies happen.",
            four: "Even at maximum effort they are very consistent. ",
        },
        {
            value: evaluation?.Consistency?.Pace,
            setValue: (v) => setValue({Pace: v}),
            label: "Pace",
            one: "Slow throughout all aspects of the track.",
            two: "Slow through corners but has some confidence in the straight aways.",
            three: "Can keep up with intermediate / advanced drivers in similar vehicles. No issues in traffic or awareness.",
            four: "Comfortable experimenting without losing pace.",
        },
        {
            value: evaluation?.Consistency?.MentalAcuity,
            setValue: (v) => setValue({MentalAcuity: v}),
            label: "Mental Acuity",
            one: "Uneasy and uncertain of themselves and the car.",
            two: "Traffic causes driving apprehension but otherwise undisturbed.",
            three: "Undisturbed in high traffic situations and being passed or held up by other drivers.",
            four: "Comfortable in close proximity to other cars and never has “red-mist” when challenged.",
        },
        {
            value: evaluation?.Consistency?.SelfDiagnosis,
            setValue: (v) => setValue({SelfDiagnosis: v}),
            label: "Self Diagnosis",
            one: "No understanding of how to critique a lap.",
            two: "Early signs of early braking and turn in but little to know knowledge of much else.",
            three: "Can self-critique with understanding not only how but why something happened.",
            four: "Intrinsic self analysis with the ability to help others and test theories to faster opportunities.",
        },
    ];
}