import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_AI,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        correlationHeaderExcludedDomains: []
    }
});
appInsights.loadAppInsights();