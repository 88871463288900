import React from 'react';
import ProfilePicture from "../../Components/ProfilePicture";
import Card from "../../Components/Card";
import Loader from "../../Components/Loader";
import useBEM from "../../useBEM";
import "./Eval.scss";
import EmailIcon from "../../Media/Email.svg";
import PhoneIcon from "../../Media/Phone.svg";

function EvalProfile(props) {
    const [block, element] = useBEM('eval');
    block();
    const {profile, evaluation} = props;

    return (
        <>
            <Loader isLoading={!evaluation || !profile}>
                <div className={element('header')}>
                    <Card>
                        <div className={element('student')}>
                            <div className={element('student-card')}>
                                <div className={element('student-picture')}>
                                    <ProfilePicture picture={profile?.Picture} />
                                </div>
                                <div className={element('student-name')}>{profile?.DisplayName}</div>
                                <div className={element('student-car')}>{evaluation?.Car}</div>
                                <div className={element('student-card-footer')}>Pending Eval</div>
                            </div>
                            <div className={element('student-contact')}>
                                <div className={element('student-phone')}>
                                    <a href={'tel:' + profile?.Phone}>
                                        <img src={PhoneIcon} alt='Phone Icon' />
                                        <span>{profile?.Phone}</span>
                                    </a> 
                                </div>
                                <div className={element('student-email')}>
                                    <a href={'mailto:' + profile?.Email}>
                                        <img src={EmailIcon} alt='Email Icon' />
                                        <span>{profile?.Email}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </Loader>
        </>
    );
}

export default EvalProfile;