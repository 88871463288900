import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import api from '../../api';
import useBEM from "../../useBEM";
import { StateContext } from '../../App';

import Loader from "../../Components/Loader";

import "./Student.scss";
import StudentProgress from './StudentProgress';
import UserCard from '../../Components/UserCard';

function StudentView() {
    const [isReady, setIsReady] = useState(false);
    
    const {studentId} = useParams();
    
    const [evals, setEvals] = useState([]);
    const [profile, setProfile] = useState({});

    const [state] = useContext(StateContext);
    const [block,element] = useBEM('student-dashboard');

    useEffect(() => {
        async function redirect() {
            if (state?.user?.userRoles?.find(x=>x === 'instructor' || x === 'admin')) {
                var evals = await api.GetEvalsForStudentById(studentId);
                setEvals(evals.Evals);
                var profile = await api.GetProfileById(studentId);
                setProfile(profile);
                setIsReady(true);
            }
        };
        if (state.user) {
            redirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user]);


    return (
        <div className={block()}>
            <h1 className={element('title')}>Student Dashboard</h1>

            <Loader isReady={isReady}>

                <div className={element('layout')}>
                    <UserCard profile={profile} />

                    <div className={element('content')}>
                        <StudentProgress evals={evals} historyLinkPrefix={`/student/view/${studentId}/eval/`} />
                    </div>
                </div>


            </Loader>
        </div>
    );
}

export default StudentView;