import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { reactPlugin } from "./AppInsights";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import Layout from './Pages/Layout';
import HomePage from './Pages/HomePage';
import AdminPage from './Pages/Admin/AdminPage';
import AdminEventDashboard from './Pages/Admin/AdminEventDashboard';
import AdminEventPage from './Pages/Admin/AdminEventPage';
import AdminEvalPage from './Pages/Admin/AdminEvalPage';

import EvalLayout from './Pages/Eval/EvalLayout';
import EvalVision from './Pages/Eval/EvalVision';
import EvalTrackUse from './Pages/Eval/EvalTrackUse';
import EvalConsistency from './Pages/Eval/EvalConsistency';
import EvalInputs from './Pages/Eval/EvalInputs';
import EvalNotes from './Pages/Eval/EvalNotes';
import EvalSubmit from './Pages/Eval/EvalSubmit';

import LinkKeyEvalPage from './Pages/LinkKeyEvalPage';

import NotFoundPage from './Pages/NotFoundPage';
import api from './api';

import './App.scss';
import ProfilePage from './Pages/Profile/ProfilePage';
import EvalView from './Pages/Eval/EvalView';
import EvalClaim from './Pages/Eval/EvalClaim';
import InstructorDashboard from './Pages/Instructor/InstructorDashboard';
import InstructorEvalPage from './Pages/Instructor/InstructorEvalPage';
import EvalWizard from './Pages/Eval/EvalWizard';
import StudentDashboard from './Pages/Student/StudentDashboard';
import StudentEvalPage from './Pages/Student/StudentEvalPage';
import AdminSearchPage from './Pages/Admin/AdminSearchPage';
import SearchPage from './Pages/Search/SeachPage';
import StudentView from './Pages/Student/StudentView';
import StudentViewEvalPage from './Pages/Student/StudentViewEvalPage';
import AdminProfilePage from './Pages/Profile/AdminProfilePage';

// Signed-in user context
export const StateContext = React.createContext();

function App() {
  const [state, setState] = useState({
    user: {}
  });
  const setPartialState = (partialStateFunction) => {
    setState(prevState => {
      const partialState = partialStateFunction(prevState);
      return {...prevState, ...partialState};
    });  
  };

  useEffect(() => {
    async function getUser() {
      var user = await api.authMe();
      if (!user) {
        window.location = `/.auth/login/b2c?post_login_redirect_uri=${window.location.pathname}`;
      }
      if (user?.claims) {
        var identityProviderClaim = user.claims.find(x=>x.typ === 'http://schemas.microsoft.com/identity/claims/identityprovider');
        if (identityProviderClaim && identityProviderClaim.val) {
          user.provider = identityProviderClaim.val;
        } else {
          user.provider = 'B2C';
        }

        var identityClaim = user.claims.find(x=>x.typ === 'emails');
        if (identityClaim && identityClaim.val) {
          user.identity = identityClaim.val;
        }
      }
      setPartialState((prev) => {
        return { user: user };
      });
    };
    getUser();
  }, []);

  return (
    <AppInsightsErrorBoundary onError={() => <h1>Something went wrong.</h1>} appInsights={reactPlugin}>
      <StateContext.Provider value={[state, setPartialState]}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route index element={<HomePage />} />

              <Route path='/evalx/:evalId' element={<EvalWizard />} />
              <Route path='/evalx/:evalId/:page' element={<EvalWizard />} />
              
              <Route path='/eval/:evalId' element={<EvalLayout />}>
                <Route index element={<EvalView />} />
                <Route path='/eval/:evalId/view' element={<EvalView />} />
                <Route path='/eval/:evalId/claim' element={<EvalClaim />} />
                <Route path='/eval/:evalId/vision' element={<EvalVision />} />
                <Route path='/eval/:evalId/track' element={<EvalTrackUse />} />
                <Route path='/eval/:evalId/inputs' element={<EvalInputs />} />
                <Route path='/eval/:evalId/consistency' element={<EvalConsistency />} />
                <Route path='/eval/:evalId/notes' element={<EvalNotes />} />
                <Route path='/eval/:evalId/submit' element={<EvalSubmit />} />
              </Route>

              <Route path='/eval/link/:linkKey' element={<LinkKeyEvalPage />} />

              <Route path='/profile' element={<ProfilePage />} />
              
              <Route path='/admin' element={<AdminPage />} />
              <Route path='/admin/:organizationId' element={<AdminEventDashboard />} />
              <Route path='/admin/:organizationId/event' element={<AdminEventDashboard />} />
              <Route path='/admin/:organizationId/event/:eventId' element={<AdminEventPage />} />
              <Route path='/admin/:organizationId/event/:eventId/roster' element={<AdminEventPage start='Roster' />} />
              <Route path='/admin/:organizationId/event/:eventId/report' element={<AdminEventPage start='Stats' />} />
              <Route path='/admin/:organizationId/eval/:evalId' element={<AdminEvalPage />} />
              <Route path='/admin/:organizationId/search' element={<AdminSearchPage />} />

              <Route path='/admin/:organizationId/profile/:userId' element={<AdminProfilePage />} />

              <Route path='/instructor' element={<InstructorDashboard />} />
              <Route path='/instructor/eval/:evalId' element={<InstructorEvalPage />} />

              <Route path='/student' element={<StudentDashboard />} />
              <Route path='/student/eval/:evalId' element={<StudentEvalPage />} />

              <Route path='/student/view/:studentId' element={<StudentView />} />
              <Route path='/student/view/:studentId/eval/:evalId' element={<StudentViewEvalPage />} />

              <Route path='/search' element={<SearchPage />} />

              <Route path='*' element={<NotFoundPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </StateContext.Provider>
    </AppInsightsErrorBoundary>

  );
}

export default withAITracking(reactPlugin, App);
