/**
 * Private function to build class names from modifiers
 * @param {String} className base class name (block or block+element)
 * @param {String|String[]} modifiers single string or array strings representing modifiers to apply
 * @returns 
 */
 function generateClassName(className, modifiers) {
    if (!modifiers) return className;

    let classOut = className;

    if (Array.isArray(modifiers)) {
        for (const modifier of modifiers) {
            if (modifier) {
                classOut += ` ${className}--${modifier}`;
            }
        }
    }

    if (typeof modifiers === 'string') {
        if (modifiers) {
            classOut += ` ${className}--${modifiers}`;
        }
    }

    return classOut;
}

/**
    * Returns functions to assist BEM class names in components. 
    * Meant to be used inside the render block (class components) or
    * similar to a hook in functional components.
    * @param {String} block The block level component name.
    * @returns {Array} Array with block and element className functions.
*/
export default function useBEM(block) {
    return [
        /**
            * Function to write a block level classname with optional modififers. 
            * @param {String[]} modifiers Modifiers is an array of strings passed in with activate modifiers.
            * @returns string Class Name for block, with appeneded modifiers if conditions were favorable.
        */
        (modifiers) => generateClassName(block, modifiers),
        /**
            * Function to write a element level classname with optional modififers. 
            * @param {String} element The element level name.
            * @param {String[]} modifiers Modifiers is an array of strings passed in with activate modifiers.
            * @returns string Class Name for element, with appeneded modifiers if conditions were favorable.
        */
        (element, modifiers) => generateClassName(`${block}__${element}`, modifiers)
    ];
}