import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'
import useBEM from "../../useBEM";
import api from '../../api';
import "../CommonEvalPage.scss";
import CommonEvalPage from '../CommonEvalPage';

function AdminEvalPage() {
    const { organizationId, evalId } = useParams();
    const [block, element] = useBEM('common-eval-page');
    const [evalWithProfile, setEvalWithProfile] = useState();
    const [evals, setEvals] = useState(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (organizationId && evalId) {
            getEvents();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, evalId]);

    const getEvents = async () => {
        setIsReady(false);
        var evalWithProfile = await api.GetEvalForAdmin(evalId, organizationId);
        if (evalWithProfile) {
            setEvalWithProfile(evalWithProfile);
        }
        setIsReady(true);

        let evals = await api.GetUserEvalsAdmin(evalWithProfile?.StudentProfile?.UserId, organizationId);
        evals = evals.filter(x=>x.EvalId !== evalId); 
        setEvals(evals);
    }

    return (
        <div className={block()}>
            <h1 className={element('title')}>Eval Summary</h1>
            <Link to={`/admin/${organizationId}/event/${evalWithProfile?.EvalModel?.EventId}`} className={element('back')}>Back</Link>

            <CommonEvalPage isReady={isReady} evalWithProfile={evalWithProfile} evals={evals} evalLinkPrefix={`admin/${organizationId}`} />
        </div>
    );
}

export default AdminEvalPage;