import React, { useContext, useEffect, useState } from 'react';
import api from '../../api';
import useBEM from "../../useBEM";
import { StateContext } from '../../App';

import { useParams } from "react-router-dom";

import Loader from "../../Components/Loader";

import "./ProfilePage.scss";
import ToggleButton from '../../Components/ToggleButton';

import UserCard from '../../Components/UserCard';
import ProfileInfo from './ProfileInfo';
import ProfileGarage from './ProfileGarage';

import Button from '../../Components/Button';

function AdminProfilePage() {
    const { userId, organizationId } = useParams();

    const [isReady, setIsReady] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const [option, setOption] = useState('Info');
    const [profile, setProfile] = useState({});

    const [state] = useContext(StateContext);
    const [block,element] = useBEM('profile');

    useEffect(() => {
        async function redirect() {
            var profile = await api.getProfileAdmin(userId, organizationId);
            setProfile(profile);
            setIsReady(true);
        };
        if (state.user) {
            redirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user]);


    function updateProfileState(partialProfile) {
        const mergedProfile = { ...profile, ...partialProfile };
        setProfile(mergedProfile);
        setIsDirty(true);
    }

    function setGarage(garage) {
        setProfile({...profile, Garage: garage});
    }

    async function confirmAndSave() {
        setIsReady(false);
        await api.saveProfileAdmin(profile, organizationId);
        setIsDirty(false);
        setIsReady(true);
    }

    return (
        <div className={block()}>
            <h1 className={element('title')}>User Profile</h1>

            <Loader isLoading={!isReady}>

                <div className={element('layout')}>
                
                    <div className={element('content', option)}>

                        <div className={element('left')}>
                            <h2 className={element('subtitle')}>My Info</h2>
                            <UserCard profile={profile} />
                            <ToggleButton options={['Info', 'Garage']} onClick={(option) => setOption(option)} selected={option} />
                            <ProfileInfo profile={profile} updateProfileState={updateProfileState}  makeDirty={() => setIsDirty(true)} />
                            <div className={element('save', 'first')}>
                                <Button label="Save Profile" onClick={() => confirmAndSave()} disabled={!isDirty}  />
                            </div>
                        </div>

                        <div className={element('right')}>
                            <h2 className={element('subtitle')}>My Garage</h2>
                            <ProfileGarage garage={profile?.Garage} setGarage={setGarage} makeDirty={() => setIsDirty(true)} />
                        </div>

                        <div className={element('save', 'second')}>
                            <Button label="Save Profile" onClick={() => confirmAndSave()} disabled={!isDirty} />
                        </div>

                    </div>

                </div>

            </Loader>
        </div>
    );
}

export default AdminProfilePage;