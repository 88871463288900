import useBEM from '../useBEM';
import './Steps.scss';

const Steps = (props) => {
    const { step, total } = props;
    const [block, element] = useBEM('steps');
    const range = Array.from({length: total}, (v, i) => i);
    return (
        <div className={block()}>
            {range.map((i)=> (
                <div key={i} className={element('step', i === step ? 'active' : '')}></div>
            ))}
        </div>
    );
}

export default Steps;