
import React from 'react';
import Card from "../../Components/Card";
import useBEM from "../../useBEM";
import "./EvalField.scss";

function EvalFieldTextArea(props) {
    const [block, element] = useBEM('eval-input');
    block();
    const {field, answer, onValueChanged} = props;
    const {label} = field;

    return (
        <div className={element('notes')}>
            <Card>
                <div>{label}</div>
                <div>
                    <textarea onChange={(e) => onValueChanged(e.target.value)} value={answer ?? ''} />
                </div>
            </Card>
        </div>
    );
}

export default EvalFieldTextArea;