import { Link } from 'react-router-dom';
import useBEM from '../useBEM';
import './Button.scss';

const Button = (props) => {
    const { disabled, to, label, kind } = props;
    const [block] = useBEM('button');
    return (
        <Link to={to} className={block([disabled ? 'disabled' : 'active', kind])}>{label}</Link>
    );
}

export default Button;