// Example POST method implementation:
async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) 
    });
    return response; // parses JSON response into native JavaScript objects
  }

  async function postForm(url = '', formData) {
    const response = await fetch(url, {
      method: 'POST',
      body: formData
    });
    return response; // parses JSON response into native JavaScript objects
  }

const api = () => {
    return {
        authMe: async () => {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            return payload.clientPrincipal;
        },
        getProfile: async () => {
            const response = await fetch('/api/GetProfile');
            const payload = await response.json();
            return payload;
        },
        saveProfile: async (profile) => {
            const response = await postData('/api/SaveProfile', profile);
            const payload = await response.json();
            return payload;
        },
        getProfileAdmin: async (userId, organizationId) => {
            const response = await fetch(`/api/GetProfileAdmin?userId=${userId}&organizationId=${organizationId}`);
            const payload = await response.json();
            return payload;
        },
        saveProfileAdmin: async (profile, organizationId) => {
            const response = await postData(`/api/SaveProfileAdmin?organizationId=${organizationId}`, profile);
            const payload = await response.json();
            return payload;
        },
        saveCar: async (car) => {
            const response = await postData('/api/SaveCar', car);
            return response.ok; 
        },
        confirmCommunication: async (confirmation) => {
            const response = await postData('/api/ConfirmCommunication', confirmation);
            const payload = await response.json();
            return payload;
        },
        GetOrganiazationsForUser: async() => {
            const response = await fetch('/api/GetOrganiazationsForUser');
            const payload = await response.json();
            return payload;
        },
        GetEventsForOrganization: async(organizationId) => {
            const response = await fetch(`/api/GetEventsForOrganization?organizationId=${organizationId}`);
            const payload = await response.json();
            return payload;
        },
        GetEvent: async(eventId, organizationId) => {
            const response = await fetch(`/api/GetEvent?eventId=${eventId}&organizationId=${organizationId}`);
            const payload = await response.json();
            return payload;
        },
        GetEvalsForEvent: async(eventId, organizationId) => {
            const response = await fetch(`/api/GetEvalsForEvent?eventId=${eventId}&organizationId=${organizationId}`);
            const payload = await response.json();
            return payload;
        },
        GetUsers: async (search) => {
            const response = await fetch(`/api/GetUsers?search=${search}`);
            const payload = await response.json();
            return payload;
        },
        GetUsersAdmin: async (search, organizationId) => {
            const response = await fetch(`/api/GetUsersAdmin?search=${search}&organizationId=${organizationId}`);
            const payload = await response.json();
            return payload;
        },
        GetEvalForAdmin: async(evalId, organizationId) => {
            const response = await fetch(`/api/GetEvalForAdmin?evalId=${evalId}&organizationId=${organizationId}`);
            const payload = await response.json();
            return payload;
        },
        GetUserEvalsPublic: async(userId) => {
            const response = await fetch(`/api/GetUserEvalsPublic?userId=${userId}`);
            const payload = await response.json();
            return payload;
        },
        GetUserEvalPublic: async(evalId, organizationId) => {
            const response = await fetch(`/api/GetUserEvalPublic?evalId=${evalId}&organizationId=${organizationId}`);
            const payload = await response.json();
            return payload;
        },
        GetUserEvalsAdmin: async(userId, organizationId) => {
            const response = await fetch(`/api/GetUserEvalsAdmin?userId=${userId}&organizationId=${organizationId}`);
            const payload = await response.json();
            return payload;
        },
        ImportEventsForOrganization: async(file, organizationId) => {
            const formData = new FormData();
            formData.append('import', file);
            const response = await postForm(`/api/ImportEventsForOrganization?organizationId=${organizationId}`, formData);
            const payload = await response.json();
            return payload;
        },

        ImportAssignmentsForEvent: async(file, eventId, organizationId) => {
            const formData = new FormData();
            formData.append('import', file);
            const response = await postForm(`/api/ImportAssignmentsForEvent?organizationId=${organizationId}&eventId=${eventId}`, formData);
            const payload = await response.json();
            return payload;
        },

        ImportEventsFromMotorsportRegForOrganization: async(organizationId) => {
            const response = await fetch(`/api/ImportEventsFromMotorsportRegForOrganization?organizationId=${organizationId}`);
            return response.ok;
        },

        ImportEventFromMotorsportRegForOrganization: async(organizationId, eventId) => {
            const response = await fetch(`/api/ImportEventFromMotorsportRegForOrganization?organizationId=${organizationId}&eventId=${eventId}`);
            return response.ok;
        },

        UpdateEvent: async(organizationId, eventModel) => {
            const response = await postData(`/api/UpdateEvent?organizationId=${organizationId}`, eventModel);
            return response.ok;
        },
        UpdateEventAttendees: async(organizationId, eventModel) => {
            const response = await postData(`/api/UpdateEventAttendees?organizationId=${organizationId}`, eventModel);
            return response.ok;
        },

        SendWelcomeEmailsForEvent: async(eventId, organizationId) => {
            const response = await postData(`/api/SendWelcomeEmails?organizationId=${organizationId}&eventId=${eventId}`, {});
            return response.ok;
        },

        PublishEvalsForEvent: async(eventId, organizationId) => {
            const response = await postData(`/api/PublishEvalsForEvent?organizationId=${organizationId}&eventId=${eventId}`, {});
            return response.ok;
        },

        GetSampleImportEventsUrl: () => {
            return '/api/GetSampleImportEvents';
        },
        GetSampleImportAssignmentsUrl: () => {
            return '/api/GetSampleImportAssignments';
        },

        ClaimEval: async(evalId) => {
            const response = await fetch(`/api/ClaimEval?evalId=${evalId}`);
            const payload = await response.json();
            return payload;
        },
        
        GetEval: async(evalId) => {
            const response = await fetch(`/api/GetEval?evalId=${evalId}`);
            const payload = await response.json();
            return payload;
        },
        GetEvalPublicLinkKey: async(linkKey) => {
            const response = await fetch(`/api/GetEvalPublicLinkKey?linkKey=${linkKey}`);
            const payload = await response.json();
            return payload;
        },

        PostEval: async(evaluation) => {
            const response = await postData('/api/PostEval', evaluation);
            return response.ok;
        },

        GetEvalsForInstructor: async() => {
            const response = await fetch('/api/GetEvalsForInstructor');
            const payload = await response.json();
            return payload;
        },
        GetEvalForInstructor: async(evalId) => {
            const response = await fetch(`/api/GetEvalForInstructor?evalId=${evalId}`);
            const payload = await response.json();
            return payload;
        },

        GetEvalsForStudentById: async(studentId) => {
            const response = await fetch(`/api/GetEvalsForStudentById?studentId=${studentId}`);
            const payload = await response.json();
            return payload;
        },

        GetProfileById: async(userId) => {
            const response = await fetch(`/api/GetProfileById?userId=${userId}`);
            const payload = await response.json();
            return payload;
        },

        GetEvalsForStudent: async() => {
            const response = await fetch('/api/GetEvalsForStudent');
            const payload = await response.json();
            return payload;
        },
        GetEvalForStudent: async(evalId) => {
            const response = await fetch(`/api/GetEvalForStudent?evalId=${evalId}`);
            const payload = await response.json();
            return payload;
        },
        GetEvalForStudentView: async(evalId) => {
            const response = await fetch(`/api/GetEvalForStudentView?evalId=${evalId}`);
            const payload = await response.json();
            return payload;
        },
        CheckMultipleAccounts: async() => {
            const response = await fetch('/api/CheckMultipleAccounts');
            const payload = await response.json();
            return payload;
        },
        MergeAccounts: async() => {
            const response = await fetch('/api/MergeAccounts');
            const payload = await response.json();
            return payload;
        },
        postMedia: async(file) => {
            const formData = new FormData();
            formData.append('File', file);
    
            const response = await fetch(
                '/api/UploadMedia',
                {
                    method: 'POST',
                    body: formData,
                }
            );
            if (!response.ok) {
                const message = await response.text();
                alert(message);
                return { }
            } else {
                const payload = await response.json();
                return payload;
            }

        }
    }
}
export default api();