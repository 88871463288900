
import React from 'react';
import useBEM from '../../useBEM';
import { Link } from "react-router-dom";
import "./StudentEvalCard.scss";

function StudentEvalCard(props) {
    const {evaluation} = props;
    const [block, element] = useBEM('student-eval-card');

    const date = new Date(evaluation.EventDate);
    return (
        <Link to={props?.link} className={block()}>
            <div>{date.toLocaleDateString()}</div>
            <div>{evaluation.Track}</div>
            <div>Apex Score:</div>
            <div className={element('score')}>{evaluation?.ApexScore?.toFixed(1)}</div>
        </Link>
    );
}

export default StudentEvalCard;