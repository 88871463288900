import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import useBEM from "../useBEM";
import api from '../api';
import "./CommonEvalPage.scss";
import CommonEvalPage from './CommonEvalPage';

function LinkKeyEvalPage() {
    const { linkKey } = useParams();
    const [block, element] = useBEM('common-eval-page');
    const [evalWithProfile, setEvalWithProfile] = useState();
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (linkKey) {
            getEval();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkKey]);

    const getEval = async () => {
        setIsReady(false);
        var evalWithProfile = await api.GetEvalPublicLinkKey(linkKey);
        if (evalWithProfile) {
            setEvalWithProfile(evalWithProfile);
        }
        setIsReady(true);
    }

    return (
        <div className={block()}>
            <h1 className={element('title')}>Eval Summary</h1>

            <CommonEvalPage isReady={isReady} evalWithProfile={evalWithProfile} evals={[]} disableDrivingHistory={true} />
        </div>
    );
}

export default LinkKeyEvalPage;