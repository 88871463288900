import React, {  useState } from 'react';
import useBEM from "../../useBEM";

import "./Student.scss";
import StudentCard from '../../Components/StudentCard';

function StudentSubmissions(props) {
    const {evals} = props;
    const pendingEvals = evals.Pending;
    const recentEvals = evals.Recent;
    const historyEvals = evals.History;

    const [search, setSearch] = useState('');

    const [block,element] = useBEM('instructor-dashboard');

    const searchedPendingEvals = !search ? pendingEvals : pendingEvals.filter(x=>x.InstructorProfile?.DisplayName?.toLowerCase().includes(search?.toLowerCase()));
    const searchedRecentEvals = !search ? recentEvals : recentEvals.filter(x=>x.InstructorProfile?.DisplayName?.toLowerCase().includes(search?.toLowerCase()));
    const searchedHistoryEvals = !search ? historyEvals : historyEvals.filter(x=>x.InstructorProfile?.DisplayName?.toLowerCase().includes(search?.toLowerCase()));

    const evalsPendingRender = searchedPendingEvals.length === 0 ? 
        <></>
        : searchedPendingEvals.map((evalWithProfile, i) =>
            <StudentCard key={i} evalWithProfile={evalWithProfile} link={`/evalx/${evalWithProfile.EvalModel.EvalId}`} />
    );

    const evalsRecentRender = searchedRecentEvals.length === 0 ? 
            <></>
        : searchedRecentEvals.map((evalWithProfile, i) =>
            <StudentCard key={i} evalWithProfile={evalWithProfile} link={`/student/eval/${evalWithProfile.EvalModel.EvalId}`} />
    );

    const evalsHistoryRender = searchedHistoryEvals.length === 0 ? 
        <></> 
        : searchedHistoryEvals.map((evalWithProfile, i) => 
            <StudentCard key={i} evalWithProfile={evalWithProfile} link={`/student/eval/${evalWithProfile.EvalModel.EvalId}`} />
    );

    return (
        <div className={block()}>
            <div className={element('submissions')}>
                <input className={element('search')} value={search} onChange={(e) => setSearch(e.target.value)} placeholder='|  Search for Instructor' />
                <h2 className={element('subtitle')}>Pending Evals</h2>
                <div className={element('slide')}>
                    {evalsPendingRender}
                </div>

                <h2 className={element('subtitle')}>Last 30 days</h2>
                <div className={element('slide')}>
                    {evalsRecentRender}
                </div>

                <h2 className={element('subtitle')}>History</h2>
                <div className={element('slide')}>
                    {evalsHistoryRender}
                </div>
            </div>
        </div>
    );
}

export default StudentSubmissions;