import useBEM from '../useBEM';
import './ProgressBar.scss';

const ProgressBar = (props) => {
    const { style, numerator, denominator, labelTop, labelBottom } = props;

    let percent = 0;
    if (numerator && denominator) {
        percent = numerator / denominator;
    }
    const [block, element] = useBEM('progress-bar');
    return (
        <div className={block(style)}>
            <div className={element('labels')}>
                <div className={element('label-top')}>{labelTop}</div>
                <div className={element('label-bottom')}>{labelBottom}</div>
            </div>
            <div className={element('graph')}>
                <div className={element('graph-inner')} style={{width: `${percent.toFixed(2) * 100}%` }}>
                    
                </div>
            </div>
        </div>
    );
}

export default ProgressBar;