
import React from 'react';
import { useNavigate } from "react-router-dom";
import Button from '../../Components/Button';
import Card from "../../Components/Card";
import Steps from '../../Components/Steps';
import useBEM from "../../useBEM";
import EvalField from './EvalField';
import BackIcon from "../../Media/arrow-left.svg";
import { getAnswerFromEvaluation } from '../../Utils/EvalContent';

function EvalSection(props) {
    const {evaluation, persistEvaluation, templateSection, page, maxPage} = props;
    const [block, element] = useBEM('eval-content');


    const fields = templateSection?.fields ? templateSection?.fields : [];
    const answers = fields.map((field) => getAnswerFromEvaluation(evaluation, templateSection.property, field.property));

    const evalFields = fields.map((field, i) => (
        <EvalField key={field.property} field={field} answer={answers[i]} onValueChanged={(value) => persistEvaluation(templateSection.property, field.property, value)} />
    ));

    const disabled = answers.filter(x=> x === null).length > 0;
                     
    const navigate = useNavigate();
    return (
        <div className={block()}>
            <Card>
                {page > 0 &&
                    <button className={element('back')} onClick={() => navigate(`../evalx/${evaluation.EvalId}/${page - 1}`)} >
                        <img src={BackIcon} alt="Back Icon" />
                    </button>
                }
                <h1 className={element('title')}>{templateSection?.title}</h1>
                <p className={element('description')}>{templateSection?.description}</p>
                <div className={element('inputs')}>
                    {evalFields}
                </div>
                <div className={element('continue')}>
                    <Button 
                        label="Continue" 
                        disabled={disabled} 
                        onClick={() => navigate(`../evalx/${evaluation.EvalId}/${page + 1}`)} />
                </div>
                <Steps total={maxPage} step={page} />
            </Card>
        </div>
    );
}

export default EvalSection;