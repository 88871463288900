import React, { useContext, useEffect, useState } from 'react';
import api from '../../api';
import useBEM from "../../useBEM";
import { StateContext } from '../../App';

import Loader from "../../Components/Loader";

import "./ProfilePage.scss";
import ToggleButton from '../../Components/ToggleButton';

import UserCard from '../../Components/UserCard';
import ProfileInfo from './ProfileInfo';
import ProfileGarage from './ProfileGarage';

import Button from '../../Components/Button';

function ProfilePage() {
    const [isReady, setIsReady] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const [option, setOption] = useState('Info');
    const [profile, setProfile] = useState({});

    const [state] = useContext(StateContext);
    const [block,element] = useBEM('profile');
    const [debug, setDebug] = useState('');

    const [confirmEmail, setConfirmEmail] = useState(false);
    const [confirmEmailCode, setConfirmEmailCode] = useState('');
    const [confirmPhone, setConfirmPhone] = useState(false);
    const [confirmPhoneCode, setConfirmPhoneCode] = useState('');

    useEffect(() => {
        async function redirect() {
            var profile = await api.getProfile();
            setProfile({...profile, identity: state?.user?.identity, provider: state?.user?.provider});
            setIsReady(true);
        };
        if (state.user) {
            redirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user]);


    function updateProfileState(partialProfile) {
        const mergedProfile = { ...profile, ...partialProfile };
        setProfile(mergedProfile);
        setIsDirty(true);
    }

    function setGarage(garage) {
        setProfile({...profile, Garage: garage});
    }

    async function confirmAndSave() {
        setIsReady(false);
        var saveResponse = await api.saveProfile(profile);
        if (saveResponse.RequiresEmailVerification || saveResponse.RequiresPhoneVerification) {
            setConfirmEmail(saveResponse.RequiresEmailVerification);
            setConfirmPhone(saveResponse.RequiresPhoneVerification);
        } 
        setIsDirty(false);
        setIsReady(true);
    }

    async function confirmCommunication() {
        setIsReady(false);
        const saveResponse = await api.confirmCommunication({ EmailCode: confirmEmailCode, PhoneCode: confirmPhoneCode});
        setConfirmEmail(saveResponse.RequiresEmailVerification);
        setConfirmPhone(saveResponse.RequiresPhoneVerification);
        if (saveResponse.RequiresEmailVerification || saveResponse.RequiresPhoneVerification) {
            setDebug('Incorrect Confirmation Code');
        } else {
            setDebug(null);
            var profile = await api.getProfile();
            setProfile(profile);
        }
        setIsDirty(false);
        setIsReady(true);
    }


    return (
        <div className={block()}>
            <h1 className={element('title')}>User Profile</h1>

            <Loader isLoading={!isReady}>


                
                <div className={element('layout')}>
                
                    <div className={element('content', option)}>

                    { (!confirmEmail && !confirmPhone) && (
                        <>
                            <div className={element('left')}>
                                <h2 className={element('subtitle')}>My Info</h2>
                                <UserCard profile={profile} />
                                <ToggleButton options={['Info', 'Garage']} onClick={(option) => setOption(option)} selected={option} />
                                <ProfileInfo profile={profile} updateProfileState={updateProfileState}  makeDirty={() => setIsDirty(true)} />
                                <div className={element('save', 'first')}>
                                    <Button label="Save Profile" onClick={() => confirmAndSave()} disabled={!isDirty}  />
                                </div>
                            </div>

                            <div className={element('right')}>
                                <h2 className={element('subtitle')}>My Garage</h2>
                                <ProfileGarage garage={profile?.Garage} setGarage={setGarage} makeDirty={() => setIsDirty(true)} />
                            </div>

                            <div className={element('save', 'second')}>
                                <Button label="Save Profile" onClick={() => confirmAndSave()} disabled={!isDirty} />
                            </div>
                        </>
                    )}

                    { (confirmEmail || confirmPhone) && (
                        <div className={element('confirm')}>
                            {confirmPhone && (
                            <div className={element('field', 'phoneConfirmation')}>
                                <label className='text' htmlFor='phoneConfirmation'>
                                    Phone Code:
                                </label>
                                <input className='text' id='phoneConfirmation' type="text" placeholder='Phone Confirmation Code' value={confirmPhoneCode ?? ''} onChange={(e) => setConfirmPhoneCode(e.target.value)}></input>
                            </div>
                            )}
                            {confirmEmail && (
                            <div className={element('field', 'emailConfirmation')}>
                                <label className='text' htmlFor='emailConfirmation'>
                                    Email Code:
                                </label>
                                <input className='text' id='emailConfirmation' type="text" placeholder='Email Confirmation Code' value={confirmEmailCode ?? ''} onChange={(e) => setConfirmEmailCode(e.target.value)}></input>
                            </div>
                            )}
                            <div className={element('debug')}>
                                {debug}
                            </div>
                            <div className={element('save')}>
                                <Button label="Confirm Communication" onClick={() => confirmCommunication()} />
                            </div>
                        </div>
                    )}

                    </div>

                </div>


            </Loader>
        </div>
    );
}

export default ProfilePage;