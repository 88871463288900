import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Card from "../../Components/Card";
import Loader from "../../Components/Loader";
import useBEM from "../../useBEM";
import api from '../../api';
import "./Eval.scss";
import { StateContext } from '../../App';
import EvalProfile from './EvalProfile';
import EvalSection from './EvalSection';
import { getApexScore, getEvaluationContent, getSectionScore, getSummary, saveAnswerToEvaluation } from '../../Utils/EvalContent';
import EvalSummary from './EvalSummary';

function EvalWizard() {
    const [state] = useContext(StateContext);
    const {evalId,page} = useParams();
    const [block] = useBEM('eval');
    const [blockM, elementM] = useBEM('eval-content');
    const [evaluation, setEvaluation] = useState();
    const [message, setMessage] = useState('');
    const [profile, setProfile] = useState();
    const [isReady, setIsReady] = useState(false);

    const effectivePage = page ? parseInt(page) : 0;
    const [template, setTemplate] = useState();
    const templateSection = template?.sections ? template.sections[effectivePage] : {};
    const maxPage = template?.sections ? template.sections.length + 1 : 1;


    const getEval = useCallback(async () => {
        setIsReady(false);
        const response = await api.ClaimEval(evalId);
        const remoteEvaluation = response.Evaluation;
        const profile = response.Profile;
        setProfile(profile);
        const template = getEvaluationContent(response.Evaluation.EvalType);
        setTemplate(template);
        try {
            const localEvaluation = JSON.parse(localStorage.getItem(evalId));
            const localUpdated = new Date(localEvaluation.LastUpdated);
            const remoteUpdated = new Date(remoteEvaluation.LastUpdated);
            if (localUpdated > remoteUpdated) {
                const updated = {...remoteEvaluation, ...localEvaluation};
                setEvaluation(updated);
            } else {
                const updated = {...localEvaluation, ...remoteEvaluation};
                setEvaluation(updated);
            }
        } catch (ex) {
            setEvaluation(remoteEvaluation);
        }
        setIsReady(true);
    }, [evalId]);

    const submitEval = async () => {
        setIsReady(false);
        evaluation.Summary = getSummary(template, evaluation);
        evaluation.ApexScore = getApexScore(template, evaluation);
        for (var section of template.sections) {
            if (section.property) {
                const average = getSectionScore(section, evaluation);
                if (average >= 0) {
                    evaluation.Answers[section.property].Average = average;
                }
            }
        }
        const success = await api.PostEval(evaluation);
        setIsReady(true);
        if (success) {
            setMessage('Success!');
        } else {
            setMessage('Failed.');
        }
    }

    useEffect(() => {
        if (!state.user) {
            window.location = `/.auth/login/b2c?post_login_redirect_uri=${window.location.pathname}`;
        }
    }, [state.user, evalId])

    useEffect(() => {
        getEval();
    }, [getEval]);

    const persistEvaluation = (section, property, value) => {
        const updatedEval = saveAnswerToEvaluation(evaluation, section, property, value);
        setEvaluation(updatedEval);
        localStorage.setItem(evalId, JSON.stringify(updatedEval));
    }

    return (
        <div className={block()}>
            <Loader isLoading={!isReady}>
                <EvalProfile profile={profile} evaluation={evaluation} />
                
                {message && 
                    <div className={blockM()}>
                        <Card>
                            <h1 className={elementM('title')}>{message}</h1>
                        </Card>
                    </div>
                }
                {!message && effectivePage + 1 < maxPage && <EvalSection 
                    evaluation={evaluation} 
                    persistEvaluation={persistEvaluation} 
                    templateSection={templateSection} 
                    page={effectivePage}
                    maxPage={maxPage} /> 
                }
                {!message && effectivePage + 1 >= maxPage && <EvalSummary
                    evaluation={evaluation}
                    template={template}
                    page={effectivePage}
                    maxPage={maxPage}
                    submitEval={submitEval} /> 
                }
            </Loader>
        </div>
    );
}

export default EvalWizard;