import React from 'react';
import useBEM from '../useBEM';
import './StudentProgressWasp.scss';

import Card from './Card';
import ProgressBar from './ProgressBar';

const StudentProgressWasp = (props) => {
  const { evals } = props;
  const [block] = useBEM('student-wasp');

  const inputs = evals.map(x => x?.Answers?.Inputs?.Average ?? 0 );
  const input = inputs.reduce((a,b)=>a+b,0);

  const visions = evals.map(x => x?.Answers?.Vision?.Average ?? 0 );
  const vision = visions.reduce((a,b)=>a+b,0);

  const consistencies = evals.map(x => x?.Answers?.Consistency?.Average ?? 0 );
  const consistency = consistencies.reduce((a,b)=>a+b, 0);

  const tracks = evals.map(x => x?.Answers?.TrackUse?.Average ?? 0 );
  const track = tracks.reduce((a,b)=>a+b, 0); 

  const count = evals.length === 0 ? 1 : evals.length;

  const data = [
    { name: 'Inputs', value: (input / count)?.toFixed(1) },
    { name: 'Consistency', value: (consistency / count)?.toFixed(1) },
    { name: 'Vision', value: (vision / count)?.toFixed(1) },
    { name: 'Track Use', value: (track / count)?.toFixed(1) },
  ].sort((a, b) => b.value - a.value);

  const renders = data.map((section) => {
    return <ProgressBar 
      numerator={section.value} 
      denominator={4} 
      labelTop={section.name} 
      labelBottom={`${section.value} out of 4`} 
    />
  });

  return (
    <div className={block()}>
      <Card>
        <h2>TOP PROFICIENCIES</h2>
        {renders[0]}
        <br />
        {renders[1]}
      </Card>
      <Card>
        <h2>TOP OPPORTUNITIES</h2>
        {renders[2]}
        <br />
        {renders[3]}
      </Card>
    </div>
  );
}

export default StudentProgressWasp;


