
import React from 'react';
import Card from "../../Components/Card";
import useBEM from "../../useBEM";
import "./EvalField.scss";

function EvalFieldSlider(props) {
    const [block, element] = useBEM('eval-input');
    const {field, answer, onValueChanged} = props;
    const {label, options} = field;
    const selectedOption = options.find(x=>x.value === answer);
    const description = selectedOption?.description;


    const buttons = options.map((option) => {
        return (
            <button 
                key={option.value}
                className={element('button', [option.value === answer ? 'selected' : '', `${option.value}`.length > 1 ? 'na' : ''] )} 
                onClick={() => onValueChanged(option.value)}
            >{option.label ?? option.value}</button>);
        }
    );

    return (
        <div className={block()}>
            <Card>
                <div className={element('label')}>{label}</div>
                <div className={element('buttons')}>
                    {buttons}
                </div>
                <div className={element('description')}>{description}</div>
            </Card>
        </div>
    );
}

export default EvalFieldSlider;