import React from 'react';
import useBEM from '../useBEM';
import './StudentProgressChart.scss';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, YAxis, XAxis, LabelList } from 'recharts';

const StudentProgressChart = (props) => {
    const { evals } = props;
    const [block] = useBEM('student-progress-chart');

    var data = evals.map(x=> {
      var name = x.EventDate;
      if (name) {
        try {
          const date = new Date(x.EventDate);
          const dateOptions = { month: 'short', day: 'numeric'};
          if (date.getFullYear() !== new Date().getFullYear())
          {
            dateOptions.year = '2-digit';
          }
          name = date.toLocaleString('en-US', dateOptions );
        } catch {
          name = x.Track;
        }
      }
      return {
        score: x.ApexScore?.toFixed(1),
        name: name
      };
    });

    return (
        <div className={block()}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart width={150} height={40} data={data}>
              <CartesianGrid strokeDasharray="5 0" vertical={false} horizontalStroke={'var(--chart-line)'} />
              <YAxis domain={[0, 4]} stroke="var(--white)" width={ 20 } />
              <XAxis dataKey="name" stroke="var(--white)" />
              <Bar dataKey="score" fill="var(--chart-bar)">
                <LabelList dataKey="score" position="insideTop" fill="var(--white)" />
              </Bar>

            </BarChart>
          </ResponsiveContainer>
        </div>
    );
}

export default StudentProgressChart;


