import useBEM from '../useBEM';
import './Card.scss';

const Card = (props) => {
    const { children } = props;
    const [block] = useBEM('card')
    return (
        <div className={block()}>
            {children}
        </div>
    );
}

export default Card;