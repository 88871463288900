import useBEM from '../useBEM';
import './Modal.scss';
import Button from './Button';
import Loader from './Loader';

const Modal = (props) => {
    const { 
        children, 
        labelYes, 
        onYesClick, 
        labelNo, 
        onNoClick,
        isLoading,
        isOpen
    } = props;
    const [block, element] = useBEM('modal')

    const yesClick = (e) => {
        if (onYesClick) {
            onYesClick();
        }
    }

    const noClick = (e) => {
        if (onNoClick) {
            onNoClick();
        }
    }

    if (!isOpen) return null;

    return (
        <div className={element('background')}>
            <Loader isLoading={isLoading}>
                <div className={block()}>
                    <div className={element('content-container')}>
                        {children}
                    </div>
                    <div className={element('buttons')}>
                        {labelYes && (
                            <Button label={labelYes} onClick={yesClick} />
                        )}
                        
                        <Button label={labelNo ?? 'Close'} onClick={noClick} />
                    </div>
                </div>
            </Loader>
        </div>
    );
}

export default Modal;