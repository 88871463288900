import React from 'react';
import useBEM from "../../useBEM";

import "./Student.scss";
import StudentEvalCard from './StudentEvalCard';
import StudentProgressChart from '../../Components/StudentProgressChart';
import StudentProgressWasp from '../../Components/StudentProgressWasp';
import Card from '../../Components/Card';

function StudentProgress(props) {
    const {evals, historyLinkPrefix} = props;
    const [block,element] = useBEM('student-dashboard');

    const historyRender = evals.length === 0 ? 
        <></>
        : evals.map((evalWithProfile, i) =>
            <StudentEvalCard key={i} evaluation={evalWithProfile} link={ (historyLinkPrefix ?? '/student/eval/') + `${evalWithProfile.EvalId}`} />
    );

    return (
        <div className={block()}>

            <div className={element('charts')}>
                <Card>
                    <div className={element('apex')}>
                        <h2 className={element('subtitle')}>Overall Apex Score</h2>
                        <StudentProgressChart evals={evals} />
                    </div>
                </Card>


                <div className={element('wasp')}>
                    <StudentProgressWasp evals={evals} />
                </div>

            </div>

            <div className={element('submissions')}>

                <h2 className={element('title')}>Driving History</h2>
                <div className={element('slide')}>
                    {historyRender}
                </div>
            </div>
        </div>
    );
}

export default StudentProgress;