import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, Outlet } from "react-router-dom";
import ProfilePicture from "../../Components/ProfilePicture";
import Card from "../../Components/Card";
import Loader from "../../Components/Loader";
import useBEM from "../../useBEM";
import api from '../../api';
import "./Eval.scss";
import { StateContext } from '../../App';
import EmailIcon from "../../Media/Email.svg";
import PhoneIcon from "../../Media/Phone.svg";

function EvalLayout() {
    const [state] = useContext(StateContext);
    const { evalId } = useParams();
    const [block, element] = useBEM('eval');
    const [evaluation, setEvaluation] = useState();
    const [profile, setProfile] = useState();
    const [isReady, setIsReady] = useState(false);

    const getEval = useCallback(async () => {
        setIsReady(false);
        const response = await api.GetEval(evalId);
        const remoteEvaluation = response.Evaluation;
        const profile = response.Profile;
        setProfile(profile);
        try {
            const localEvaluation = JSON.parse(localStorage.getItem(evalId));
            const localUpdated = new Date(localEvaluation.LastUpdated);
            const remoteUpdated = new Date(remoteEvaluation.LastUpdated);
            if (localUpdated > remoteUpdated) {
                const updated = {...remoteEvaluation, ...localEvaluation};
                setEvaluation(updated);
            } else {
                const updated = {...localEvaluation, ...remoteEvaluation};
                setEvaluation(updated);
            }
        } catch (ex) {
            setEvaluation(remoteEvaluation);
        }
        setIsReady(true);
    }, [evalId]);

    useEffect(() => {
        if (!state.user) {
            window.location = `/.auth/login/b2c?post_login_redirect_uri=${window.location.pathname}`;
        }
    }, [state.user, evalId])

    useEffect(() => {
        getEval();
    }, [getEval]);

    const persistEvaluation = (partial) => {
        const updatedEval = {...evaluation, ...partial, LastUpdated: new Date().toISOString() };
        setEvaluation(updatedEval);
        localStorage.setItem(evalId, JSON.stringify(updatedEval));
    }

    return (
        <div className={block()}>
            <Loader isLoading={!isReady && evaluation && profile}>
                <div className={element('header')}>
                    <Card>
                        <div className={element('student')}>
                            <div className={element('student-card')}>
                                <div className={element('student-picture')}>
                                    <ProfilePicture picture={profile?.Picture} />
                                </div>
                                <div className={element('student-name')}>{profile?.DisplayName}</div>
                                <div className={element('student-car')}>{evaluation?.Car}</div>
                                <div className={element('student-card-footer')}>Pending Eval</div>
                            </div>
                            <div className={element('student-contact')}>
                                <div className={element('student-phone')}>
                                    <a href={'tel:' + profile?.Phone}>
                                        <img src={PhoneIcon} alt='Phone Icon' />
                                        <span>{profile?.Phone}</span>
                                    </a> 
                                </div>
                                <div className={element('student-email')}>
                                    <a href={'mailto:' + profile?.Email}>
                                        <img src={EmailIcon} alt='Email Icon' />
                                        <span>{profile?.Email}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            
                <Outlet context={[evaluation, persistEvaluation]} />
            </Loader>
        </div>
    );
}

export default EvalLayout;