import React, { useState } from 'react';
import useBEM from "../../useBEM";

import StudentCard from '../../Components/StudentCard';

import "./Instructor.scss";

function InstructorSubmissions(props) {
    const {evals} = props;

    const [search, setSearch] = useState('');

    const [block,element] = useBEM('instructor-dashboard');

    const searchedPendingEvals = !search ? evals.Pending : evals.Pending.filter(x=>x.StudentProfile?.DisplayName?.toLowerCase().includes(search?.toLowerCase()));
    const searchedRecentEvals = !search ? evals.Recent : evals.Recent.filter(x=>x.StudentProfile?.DisplayName?.toLowerCase().includes(search?.toLowerCase()));
    const searchedHistoryEvals = !search ? evals.History : evals.History.filter(x=>x.StudentProfile?.DisplayName?.toLowerCase().includes(search?.toLowerCase()));

    const evalsPendingRender = searchedPendingEvals?.length >= 0 ? 
        searchedPendingEvals.map((evalWithProfile, i) =>
            <StudentCard key={i} evalWithProfile={evalWithProfile} link={`/evalx/${evalWithProfile.EvalModel.EvalId}`} />) : <></>;

    const evalsRecentRender = searchedRecentEvals?.length >= 0 ? 
        searchedRecentEvals.map((evalWithProfile, i) =>
            <StudentCard key={i} evalWithProfile={evalWithProfile} link={`/instructor/eval/${evalWithProfile.EvalModel.EvalId}`} />) : <></>;

    const evalsHistoryRender = searchedHistoryEvals?.length >= 0 ? 
        searchedHistoryEvals.map((evalWithProfile, i) => 
            <StudentCard key={i} evalWithProfile={evalWithProfile} link={`/instructor/eval/${evalWithProfile.EvalModel.EvalId}`} />) : <></>;

    return (
        <div className={block()}>
            <input className={element('search')} value={search} onChange={(e) => setSearch(e.target.value)} placeholder='|  Search for Students' />]
            
            <h2 className={element('subtitle')}>Pending Evals</h2>
            <div className={element('slide')}>
                {evalsPendingRender}
            </div>

            <h2 className={element('subtitle')}>Last 30 days</h2>
            <div className={element('slide')}>
                {evalsRecentRender}
            </div>

            <h2 className={element('subtitle')}>History</h2>
            <div className={element('slide')}>
                {evalsHistoryRender}
            </div>
        </div>
    );
}

export default InstructorSubmissions;