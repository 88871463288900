
import React from 'react';
import Card from "../Card";
import useBEM from '../../useBEM';
import "./EvalInput.scss";

function EvalVision(props) {
    const [block, element] = useBEM('eval-input');
    const {label, one, two, three, four} = props;
    const description = ['', one, two, three, four];
    let buttonValues = [1, 2, 3, 4];
    if (props['N/A']) {
        buttonValues = [-1, 1, 2, 3, 4];
        description[-1] = props['N/A']; 
    }
    const buttons = buttonValues.map((i) => {
        const label = i === -1 ? 'N/A' : i;
        return (
            <button 
                key={i}
                className={element('button', [i === props.value ? 'selected' : '', i === -1 ? 'na' : ''] )} 
                onClick={() => onValueChanged(i)}
            >{label}</button>);
        }
    );

    const onValueChanged = (v) => {
        if (props.setValue) {
            props.setValue(v);
        }
    }

    return (
        <div className={block()}>
            <Card>
                <div className={element('label')}>{label}</div>
                <div className={element('buttons')}>
                    {buttons}
                </div>
                <div className={element('description')}>{description[props.value]}</div>
            </Card>
        </div>
    );
}

export default EvalVision;