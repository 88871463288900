import React, { useEffect, useState, useContext } from 'react';
import { StateContext } from '../App';
import Modal from "./Modal";
import api from '../api';

const MergeAccountModal = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [state] = useContext(StateContext);

    useEffect(() => {
        async function checkAccount() {
            var checkAccount = await api.CheckMultipleAccounts();
            if (checkAccount.HasOtherAccount) {
                setIsOpen(true);
            }
        };
        if (state.user) {
            checkAccount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user]);

    const yesClick = async (e) => {
        setIsLoading(true);
        await api.MergeAccounts();
        setIsLoading(false);
        setIsOpen(false);
        window.location.reload(false);
    }

    const noClick = (e) => {
        setIsOpen(false);
    }

    return <Modal 
        labelYes='Merge' 
        labelNo='Close' 
        onNoClick={noClick}
        onYesClick={yesClick}
        isLoading={isLoading} 
        isOpen={isOpen}>
            <h2>Multiple Accounts Detected</h2>
            <div>
                Multiple accounts exist for account {state?.user?.identity}. Would you like to merge evals from those accounts to this one and then disable them?
            </div>
    </Modal>
}

export default MergeAccountModal;