import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import useBEM from "../../useBEM";
import api from '../../api';
import "../CommonEvalPage.scss";
import "./SearchPage.scss";
import UserCard from '../../Components/UserCard';
import CommonEvalPage from '../CommonEvalPage';

function SearchPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [block, element] = useBEM('admin-page');
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState();

    const [evals, setEvals] = useState([]);
    const [evalModel, setEval] = useState();

    useEffect(() => {
        if (searchParams.get('q')) {
            setSearch(searchParams.get('q'));
        }
      }, [searchParams]);

    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        setUser();
        setEvals([]);
        if (search?.length > 1) {
            setSearchParams({q: search});
            api.GetUsers(search).then((result) => {
                setUsers(result);
            });
        } else {
            setUsers([]);
        }

      }, 1000);
  
      return () => clearTimeout(delayDebounceFn)
    }, [search, setSearchParams]);

    // useEffect(() => {
    //     const delayDebounceFn = setTimeout(() => {
    //       if (user) {
    //           api.GetUserEvalsPublic(user.UserId).then((result) => {
    //               setEvals(result);
    //           });
    //       } else {
    //         setEvals([]);
    //       }
  
    //     }, 1000);
    
    //     return () => clearTimeout(delayDebounceFn)
    //   }, [user]);

    const getEvalWithProfile = (evalId, organizationId) => {
        api.GetUserEvalPublic(evalId, organizationId).then((result) => {
            console.log(result);
            setEval(result);
        });
    }

    const userCardClick = (u) => {
        setUser(u); 
        setEvals([]);
    }

    const userCards = users.map((u) => {
        return <Link key={u.UserId} className={element('searchuserCard', u?.UserId === user?.UserId ? 'selected' : '')} onClick={() => userCardClick(u)} 
                    to={`/student/view/${u.UserId}`}>
            <UserCard  profile={u} />
        </Link>
    });

    const evalCards = evals?.map((e, i) => {
        let title = '';
        if (e?.EvalTypeString === 'Student' && e?.UserId === user.UserId) {
            title = `${user.DisplayName} evaluating instructor`;
        } else if (e?.EvalTypeString === 'Student' && e?.ProfileId === user.UserId) {
            title = `Student evaluating ${user.DisplayName}`;
        } else if (e?.EvalTypeString === 'Teacher' && e?.UserId === user.UserId) {
            title = `${user.DisplayName} evaluating student`;
        } else if (e?.EvalTypeString === 'Teacher' && e?.ProfileId === user.UserId) {
            title = `Instructor evaluating ${user.DisplayName}`;
        } else {
            title = `${user.DisplayName} evaluation`;
        }
        return (
            <button key={i} className={element('search-history-eval')} onClick={() => getEvalWithProfile(e?.EvalId, e?.OrganizationId)}>
                <div>{title}</div>
                {e?.EventDate && <div>{new Date(e?.EventDate).toLocaleDateString()}</div>}
                {e?.Track && <div>{e?.Track}</div>}
                <div>Apex Score:</div>
                <div className={element('history-eval-score')}>{e?.ApexScore.toFixed(1)}</div>
            </button>
        );
    });

    if (evalModel) {
        return (
            <div className={block()}>
                <br />
                <button href="#" onClick={() => setEval(null)} className="search-back">Back</button>
                <CommonEvalPage isReady={true} evalWithProfile={evalModel} disableDrivingHistory={true} />
            </div>
        );
    } else {
        return (
            <div className={block()}>
                <h1 className={element('title')}>User Search</h1>

                <div className={element('search')}>
                    <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search for Users' />
                </div>

                { userCards.length === 0 && (
                <div className={element('info')}>
                    No Users Found
                </div>
                )}

                { userCards.length > 0 && (
                <div className={element('searchusers')}>
                    {userCards}
                </div>
                )}

                { user && evalCards.length === 0 && (
                <div className={element('info')}>
                    No Evals for {user.DisplayName}
                </div>
                )}

                { evalCards.length > 0 && (
                <div className={element('evals')}>
                    {evalCards}
                </div>
                )}
            </div>
        );
    }
}

export default SearchPage;