import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'
import useBEM from "../../useBEM";
import api from '../../api';
import "../CommonEvalPage.scss";
import UserCard from '../../Components/UserCard';
import "../Search/SearchPage.scss";

function AdminSearchPage() {
    const { organizationId } = useParams();
    const [block, element] = useBEM('admin-page');


    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState();


    const [evals, setEvals] = useState([]);

    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        setUser();
        setEvals([]);
        if (search?.length > 1) {
            api.GetUsersAdmin(search, organizationId).then((result) => {
                setUsers(result);
            });
        } else {
            setUsers([]);
        }

      }, 1000);
  
      return () => clearTimeout(delayDebounceFn)
    }, [search, organizationId]);

    // useEffect(() => {
    //     const delayDebounceFn = setTimeout(() => {
    //       if (user) {
    //           api.GetUserEvalsAdmin(user.UserId, organizationId).then((result) => {
    //               setEvals(result);
    //           });
    //       } else {
    //           setEvals([]);
    //       }
  
    //     }, 1000);
    
    //     return () => clearTimeout(delayDebounceFn)
    //   }, [user, organizationId]);

    const userCardClick = (user) => {
        setUser(user); 
        setEvals([]);
    }


    const userCards = users.map((u) => {
        return <div key={u.UserId} className={element('admin-card')}>
            <Link className={element('searchuserCard', u?.UserId === user?.UserId ? 'selected' : '')} onClick={() => { userCardClick(u) }}
                to={`/student/view/${u.UserId}`}>
                <UserCard  profile={u} />
            </Link>
            <Link to={`/admin/${organizationId}/profile/${u.UserId}`} className={element('admin-edit')}>Edit</Link>
        </div>
    });

    const evalCards = evals?.map((e, i) => {
        return (
            <Link key={i} className={element('history-eval')} to={`/admin/${e.OrganizationId}/eval/${e.EvalId}`}>
                {e?.EventDate && <div>{new Date(e?.EventDate).toLocaleDateString()}</div>}
                {e?.Track && <div>{e?.Track}</div>}
                <div>Apex Score:</div>
                <div className={element('history-eval-score')}>{e?.ApexScore.toFixed(1)}</div>
            </Link>
        );
    });

    return (
        <div className={block()}>
            <h1 className={element('title')}>User Search</h1>

            <div className={element('search')}>
                <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search for Users' />
            </div>

            { userCards.length === 0 && (
            <div className={element('info')}>
                No Users Found
            </div>
            )}

            { userCards.length > 0 && (
            <div className={element('searchusers')}>
                {userCards}
            </div>
            )}

            { user && evalCards.length === 0 && (
            <div className={element('info')}>
                No Evals for {user.DisplayName}
            </div>
            )}

            { evalCards.length > 0 && (
            <div className={element('evals')}>
                {evalCards}
            </div>
            )}
        </div>
    );
}

export default AdminSearchPage;