import { Link } from 'react-router-dom';
import useBEM from '../useBEM';
import ProfilePicture from './ProfilePicture';
import './StudentCard.scss';

const StudentCard = (props) => {
    const { evalWithProfile, link } = props;
    const [block,element] = useBEM('student-card');

    console.log(evalWithProfile);

    const studentCardContent = evalWithProfile.EvalModel.EvalType === 1 ? (
        <>
            <div className={element('instructor')}>{evalWithProfile.InstructorProfile?.DisplayName}</div>
            <div className={element('evaluating')}>evaluating</div>
            <div className={element('picture')}>
                <ProfilePicture picture={evalWithProfile.StudentProfile?.Picture} />
            </div>
            <div className={element('student')}>{evalWithProfile.StudentProfile?.DisplayName}</div>
            <div className={element('car')}>{evalWithProfile.EvalModel?.Car}</div>
            <div className={element('card-footer', evalWithProfile.EvalModel?.EvalStateString)}>{evalWithProfile.EvalModel?.EvalStateString}</div>
        </>
    ) : (
        <>
            <div className={element('instructor')}>{evalWithProfile.StudentProfile?.DisplayName}</div>
            <div className={element('evaluating')}>evaluating</div>
            <div className={element('picture')}>
                <ProfilePicture picture={evalWithProfile.InstructorProfile?.Picture} />
            </div>
            <div className={element('student')}>{evalWithProfile.InstructorProfile?.DisplayName}</div>
            <div className={element('car')}>{evalWithProfile.EvalModel?.Car}</div>
            <div className={element('card-footer', evalWithProfile.EvalModel?.EvalStateString)}>{evalWithProfile.EvalModel?.EvalStateString}</div>
        </>
    );

    if (link) {
        return (
            <div className={block()}>
                <Link to={link} className={element('link')}>
                    {studentCardContent}
                </Link>
            </div>
        );
    } else {
        return (
            <div className={block()}>
                {studentCardContent}
            </div>
        );
    }
}

export default StudentCard;