
import React from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import Button from '../../Components/Button';
import Card from "../../Components/Card";
import Steps from '../../Components/Steps';
import useBEM from "../../useBEM";
import BackIcon from "../../Media/arrow-left.svg";

function EvalNotes() {
    const [evaluation, persistEvaluation] = useOutletContext();
    const [block, element] = useBEM('eval-content');

    const persist = (partial) => {
        persistEvaluation({
            ...partial
        });
    };

    const disabled = false;
                     
    const navigate = useNavigate();
    return (
        <div className={block()}>
            <Card>
                <button className={element('back')} onClick={() => navigate('../consistency')}>
                    <img src={BackIcon} alt="Back Icon" />
                </button>
                <h1 className={element('title')}>Open Forms</h1>
                <div className={element('inputs')}>
                    <div className={element('notes')}>
                        <label>
                            Internal Notes
                            <textarea onChange={(e) => persist({PrivateNotes: e.target.value})} value={evaluation?.PrivateNotes}></textarea>
                        </label>
                    </div>
                    <div className={element('notes')}>
                        <label>
                            External Notes for Student
                            <textarea onChange={(e) => persist({PublicNotes: e.target.value})} value={evaluation?.PublicNotes}></textarea>
                        </label>
                    </div>

                </div>
                <div className={element('continue')}>
                    <Button 
                        label="Continue" 
                        disabled={disabled} 
                        onClick={() => navigate('../submit')} />
                </div>
                <Steps total={5} step={4} />
            </Card>
        </div>
    );
}

export default EvalNotes;