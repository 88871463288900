
import React from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import Button from '../../Components/Button';
import Card from "../../Components/Card";
import EvalInput from "../../Components/Eval/EvalInput";
import Steps from '../../Components/Steps';
import useBEM from "../../useBEM";
import BackIcon from "../../Media/arrow-left.svg";
import { getInputsContent } from '../../Utils/EvalContent';

function EvalInputs() {
    const [evaluation, persistEvaluation] = useOutletContext();
    const [block, element] = useBEM('eval-content');

    const persist = (partial) => {
        persistEvaluation({
            Inputs: {
                ...evaluation.Inputs,
                ...partial
            }
        });
    };

    const evalInputProps = getInputsContent(evaluation, persist);

    const evalInputsMap = evalInputProps.map((props, i) => (
        <EvalInput {...props} key={i} />
    ));

    const disabled = !evaluation?.Inputs?.Throttle || evaluation?.Inputs?.Throttle === 0 ||
                     !evaluation?.Inputs?.Steering || evaluation?.Inputs?.Steering === 0 ||
                     !evaluation?.Inputs?.WeightControl || evaluation?.Inputs?.WeightControl === 0 ||
                     !evaluation?.Inputs?.ShiftTimingControl || evaluation?.Inputs?.ShiftTimingControl === 0;

    const navigate = useNavigate();
    return (
        <div className={block()}>
            <Card>
                <button className={element('back')} onClick={() => navigate('../track')}>
                    <img src={BackIcon} alt="Back Icon" />
                </button>
                <h1 className={element('title')}>Inputs</h1>
                <p className={element('description')}>
                    Rate your student using the scale below. 
                    1 is novice level and 4 is advanced. 
                    After selection a brief description will be shown with what it entails.
                </p>
                <div className={element('inputs')}>
                    {evalInputsMap}
                </div>
                <div className={element('continue')}>
                    <Button 
                        label="Continue" 
                        disabled={disabled} 
                        onClick={() => navigate("../consistency")} />
                </div>
                <Steps total={5} step={2} />
            </Card>
        </div>
    );
}

export default EvalInputs;