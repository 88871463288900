
import React from 'react';
import { useNavigate } from "react-router-dom";
import Button from '../../Components/Button';
import Card from "../../Components/Card";
import Steps from '../../Components/Steps';
import useBEM from "../../useBEM";
import BackIcon from "../../Media/arrow-left.svg";
import EvalSectionSummary from './EvalSectionSummary';
import { getAnswerFromEvaluation } from '../../Utils/EvalContent';

function EvalSummary(props) {
    const {evaluation, template, page, maxPage, submitEval} = props;
    const [block, element] = useBEM('eval-content');


    const sectionSummaries = template.sections.map((templateSection, i) => {

        const fields = templateSection?.fields ? templateSection?.fields : [];
        const properties = fields.map((field) => {
            const value = getAnswerFromEvaluation(evaluation, templateSection.property, field.property);
            return {
                value: value,
                label: field.label,
                property: field.property,
                type: field.type,
            }
        });

        const sectionProps = {
            properties: properties,
            label: templateSection.title,
            labelLink: `../evalx/${evaluation.EvalId}/${i}`
        };

        return (
            <EvalSectionSummary key={i} {...sectionProps} />
        );
    });
                     
    const navigate = useNavigate();
    return (
        <div className={block()}>
            <Card>
                {page > 0 &&
                    <button className={element('back')} onClick={() => navigate(`../evalx/${evaluation.EvalId}/${page - 1}`)} >
                        <img src={BackIcon} alt="Back Icon" />
                    </button>
                }
                <h1 className={element('title')}>Summary</h1>
                <div className={element('summaries')}>
                    {sectionSummaries}
                </div>
                <div className={element('continue')}>
                    <Button 
                        label="Submit" 
                        onClick={() => submitEval()} />
                </div>
                <Steps total={maxPage} step={page} />
            </Card>
        </div>
    );
}

export default EvalSummary;