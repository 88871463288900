import useBEM from '../useBEM';
import './ToggleButton.scss';

const ToggleButton = (props) => {
    const { options, onClick, selected } = props;
    const [block, element] = useBEM('toggle-button');
    
    const handleClick = (option) => {
        if (onClick) {
            onClick(option);
        }
    };
    
    return (
        <div className={block()}>
            { options.map((option, index)=>{
                return (
                    <button 
                        key={index} 
                        onClick={() => handleClick(option)}
                        className={element('option', option === selected ? 'selected' : '')}>
                        {option}
                    </button>
                );
            })}
        </div>
    );
}

export default ToggleButton;