import { useContext, useState } from 'react';
import useBEM from '../useBEM';
import { Link } from "react-router-dom";
import { StateContext } from '../App';
import './Navigation.scss';

const Navigation = () => {
  const [block, element] = useBEM('navigation');
  const [open, setOpen] = useState(false);
  const [state] = useContext(StateContext);

  return (
    <nav className={block()}>
      <div className={element('hamburger', open ? 'open' : '')} onClick={() => setOpen(!open)}>
        <input type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className={element('menu', open ? 'open' : '')}>
        <h1>Apex Evals</h1>
        <ul className={element('list')}>
          {
            state.user?.userRoles && (
              <li className={element('link')}>
                <Link to="/profile">Profile</Link>
              </li>
            )
          }
          <li className={element('link')}>
            <Link to="/">Home</Link>
          </li>
          {
            state.user?.userRoles?.indexOf('admin') > -1 && (
              <li className={element('link')}>
                <Link to="/admin">Admin</Link>
              </li>
            )
          }
          {
            !state.user?.userRoles && (
              <li className={element('link')}>
                <a href={`/.auth/login/b2c?post_login_redirect_uri=${window.location}`}>Login</a>
              </li>
            )
          }
          {
            state.user?.userRoles && (
              <li className={element('link')}>
                <a href="https://apexevalsb2c.b2clogin.com/apexevalsb2c.onmicrosoft.com/b2c_1_signinsignup/oauth2/v2.0/logout?post_logout_redirect_uri=https://www.apexevals.com/.auth/logout">Logout</a>
              </li>
            )
          }
          <li className={element('link')}>
            <Link to="/search">Search</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navigation;