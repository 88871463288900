import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Card from "../Components/Card";
import { getAnswerFromEvaluation, getEvaluationContent } from '../Utils/EvalContent';
import StudentCard from '../Components/StudentCard';
import EmailIcon from "../Media/Email.svg";
import PhoneIcon from "../Media/Phone.svg";
import Loader from "../Components/Loader";
import useBEM from "../useBEM";
import "./CommonEvalPage.scss";
import EvalSectionSummary from './Eval/EvalSectionSummary';

function CommonEvalPage(props) {
    //EvalSummary props
    //const {evaluation, template, page, maxPage, submitEval} = props;
    const { evalWithProfile, evals, isReady, evalLinkPrefix, disableDrivingHistory} = props;

    const evaluation = evalWithProfile?.EvalModel;
    const [ template, setTemplate] = useState();

    useEffect(() => {
        if (evalWithProfile) {
            const template = getEvaluationContent(evalWithProfile?.EvalModel?.EvalType);
            setTemplate(template);
        }
    }, [evalWithProfile]);

    const [block, element] = useBEM('common-eval-page');

    const sectionSummaries = !template ? <></> : template.sections.map((templateSection, i) => {
        const fields = templateSection?.fields ? templateSection?.fields : [];
        const properties = fields.map((field) => {
            const value = getAnswerFromEvaluation(evaluation, templateSection.property, field.property);
            return {
                value: value,
                label: field.label,
                property: field.property,
                type: field.type,
            }
        });

        const sectionProps = {
            properties: properties,
            label: templateSection.title
        };

        return (
            <EvalSectionSummary key={i} {...sectionProps} />
        );
    });

    return (
        <div className={block()}>
            <Loader isLoading={!isReady}>
                <div className={element('layout')}>
                    <div className={element('header')}>
                        <div className={element('profile')}>
                            <Card>
                                <div className={element('student')}>
                                    <StudentCard evalWithProfile={evalWithProfile} />
                                    <div className={element('student-contact')}>
                                        {evalWithProfile?.StudentProfile?.Phone && <div className={element('student-phone')}>
                                            <a href={'tel:' + evalWithProfile?.StudentProfile?.Phone}>
                                                <img src={PhoneIcon} alt='Phone Icon' />
                                                <span>{evalWithProfile?.StudentProfile?.Phone}</span>
                                            </a> 
                                        </div>}
                                        {evalWithProfile?.StudentProfile?.Email && <div className={element('student-email')}>
                                            <a href={'mailto:' + evalWithProfile?.StudentProfile?.Email}>
                                                <img src={EmailIcon} alt='Email Icon' />
                                                <span>{evalWithProfile?.StudentProfile?.Email}</span>
                                            </a>
                                        </div>}
                                    </div>
                                </div>
                                <div className={element('extra-info')}>
                                    {evalWithProfile?.EvalModel?.EventDate && <div>Date: {evalWithProfile?.EvalModel?.EventDate}</div>}
                                    {evalWithProfile?.EvalModel?.Track && <div>Track: {evalWithProfile?.EvalModel?.Track}</div>}
                                </div>
                            </Card>
                        </div>
                    

                        <div className={element('content')}>
                            <div className={element('summaries')}>
                                {sectionSummaries}
                            </div>
                        </div>
                    </div>

                    {!(disableDrivingHistory === true) && (
                    <Card>
                        <Loader isLoading={!evals}>
                            <div className={element('history')}>
                                <h2>Driving History</h2>
                                <div className={element('history-container')}>    
                                    {evals?.length === 0 && (
                                    <div>
                                        No previous driving history for user.
                                    </div>
                                    )}
                                    {evals?.length > 0 && evals.map((e, i) => {
                                        return (
                                            <Link key={i} className={element('history-eval')} to={`/${evalLinkPrefix}/eval/${e?.EvalId}`}>
                                                {e?.EventDate && <div>{new Date(e?.EventDate).toLocaleDateString()}</div>}
                                                {e?.Track && <div>{e?.Track}</div>}
                                                <div>Apex Score:</div>
                                                <div className={element('history-eval-score')}>{e?.ApexScore.toFixed(1)}</div>
                                            </Link>
                                        );
                                    })}
                                </div>
                            </div>
                        </Loader>
                    </Card>
                    )}

                </div>
            </Loader>
        </div>
    );
}

export default CommonEvalPage;