import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'
import useBEM from "../../useBEM";
import api from '../../api';
import "../CommonEvalPage.scss";
import CommonEvalPage from '../CommonEvalPage';

function StudentViewEvalPage() {
    const { studentId, evalId } = useParams();
    const [block, element] = useBEM('common-eval-page');
    const [evalWithProfile, setEvalWithProfile] = useState();
    const [evals, setEvals] = useState(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (evalId) {
            getEvals();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evalId]);

    const getEvals = async () => {
        setIsReady(false);
        var response = await api.GetEvalForStudentView(evalId);
        if (response) {
            setEvalWithProfile(response.EvalWithProfile);
            setEvals(response.History);
        }
        setIsReady(true);
    }

    return (
        <div className={block()}>
            <h1 className={element('title')}>Eval Summary</h1>
            <Link to={`/student/view/${studentId}`} className={element('back')}>Back</Link>

            <CommonEvalPage isReady={isReady} evalWithProfile={evalWithProfile} evals={evals} evalLinkPrefix={`student/view/${studentId}`} />
        </div>
    );
}

export default StudentViewEvalPage;