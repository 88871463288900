
import React, { useEffect, useState } from 'react';
import Card from "../../Components/Card";
import useBEM from '../../useBEM';
import "./EvalSectionSummary.scss";
import EditIcon from "../../Media/edit.svg";
import { Link } from 'react-router-dom';
import { fieldTypes } from '../../Utils/EvalContent';

function EvalSectionSummary(props) {
    const [block, element] = useBEM('eval-summary');
    const [ showAverage, setShowAverage ] = useState(true);
    const [ average, setAverage ] = useState(0);
    const { properties, label, labelLink } = props;

    const isWide = !properties.find(x=>x.type !== fieldTypes.TEXTAREA);
    
    const mappedProperties = properties.filter(x=>x.value).map((prop, i) => {
        const className = properties.find(x=>x.type !== fieldTypes.TEXTAREA) ? 'property' : 'notes';
        return (
            <div key={i} className={element(className)}>
                <div className={element('property-label')}>{prop.label}</div>
                {prop.type === fieldTypes.SLIDER && 
                <div className={element('value')}>{prop.value === -1 ? 'N/A' : prop.value}</div>
                }
                {prop.type === fieldTypes.TEXTAREA && 
                <div className={element('value-notes')}>{prop.value}</div>
                }
            </div>
        )
    });



    useEffect(() => {
        const values = properties.map(property => property.value);
        let total = 0;
        let count = 0;
        let average = 0;
        for (var value of values) {
            try {
                let parsed = parseInt(value);
                if (parsed > 0) {
                    total += parsed;
                    count += 1;
                }
            } catch {
            }
        }
        if (count > 0) {
            average = (total / count);
        }
        setAverage(average);
        setShowAverage(count > 0);
    }, [properties]);

    

    return (
        <div className={block(isWide ? 'wide' : '')}>
            <Card>
                <div className={element('label')}>
                    {!!labelLink && (
                        <Link to={labelLink}>
                            {label}
                            <img src={EditIcon} alt={`Edit ${label} Icon`} />
                        </Link>
                    )}
                    {!labelLink && (
                        <>{label}</>
                    )}
                </div>
                <div className={element('summaries')}>
                    {mappedProperties}
                </div>
                {showAverage &&
                <div className={element('graph')}>
                    <div className={element('graph-labels')}>
                        <div className={element('graph-label')}>{label}</div>
                        <div className={element('graph-average')}>{average.toFixed(1)} out of 4</div>
                    </div>
                    <div className={element('graph-outer')}>
                        <div className={element('graph-inner')} style={{width: `${average * (100 / 4)}%` }}>
                            
                        </div>
                    </div>
                </div>
                }
            </Card>
        </div>
    );
}

export default EvalSectionSummary;