import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Card from "../../Components/Card";
import Loader from "../../Components/Loader";
import useBEM from "../../useBEM";
import api from '../../api';
import "./AdminPage.scss";

function AdminPage() {
    const [block, element] = useBEM('admin-page');
    const [organizations, setOrganizations] = useState([]);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        async function getOrganizations() {
            var organizations = await api.GetOrganiazationsForUser();
            setOrganizations(organizations);
            setIsReady(true);
        };
        setIsReady(false);
        getOrganizations();
    }, []);

    return (
        <div className={block()}>
            <Card>
                <h1 className={element('title')}>Admin</h1>
                <h2 className={element('title')}>Organizations</h2>

                <Loader isLoading={!isReady}>
                    <ul className={element('organizations')}>
                        {organizations.map((org) => {
                            return (
                                <li key={org.OrganizationId}>
                                    <Link to={"/admin/" + org.OrganizationId}>{org.Name}</Link>
                                </li>
                            );
                        })}
                    </ul>
                </Loader>

            </Card>
        </div>
    );
}

export default AdminPage;